import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/User/SignIn.vue'
import Users from '../views/User/Users.vue'
import Clients from '../views/Client/Clients.vue'
import Packs from '../views/Pack/Packs.vue'
import Events from '../views/Event/Events.vue'
import Reservations from '../views/Reservation/Reservations.vue'
import ProspectNotes from '../views/Prospect/ProspectNotes.vue'
import ProspectMessages from '../views/Prospect/ProspectMessages.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'notes',
        component: ProspectNotes,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/signin',
        name: 'signin',
        component: SignIn,
        beforeEnter: (to, from, next) => {

            if (store.getters['auth/authenticated']) {

                return next({
                    name: 'dashboard'
                })
            }

            next()
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/clients',
        name: 'clients',
        component: Clients,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/packs',
        name: 'packs',
        component: Packs,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/events',
        name: 'events',
        component: Events,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/reservations',
        name: 'reservations',
        component: Reservations,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/notes',
        name: 'notes',
        component: ProspectNotes,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    {
        path: '/conversations/:phone?',
        name: 'conversations',
        component: ProspectMessages,
        beforeEnter: (to, from, next) => {

            if (!store.getters['auth/authenticated']) {

                return next({
                    name: 'signin'
                })
            }

            next()
        }
    },
    /*{
        path: '/reservations/verify',
        name: 'verify_reservations',
        component: VerifyReservations
    }*/
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router