import colorHelper from '@/libs/helpers/colorHelper'
import validationHelper from '@/libs/helpers/validationHelper'
import globalDataHelper from '@/libs/helpers/globalDataHelper'

export default {
    mixins: [
        colorHelper,
        validationHelper,
        globalDataHelper
    ],
    name: "notes",
    data: () => ({
        datatables: {
            notes: {
                options: {
                    itemsPerPage: 50
                }
            }
        },
        dialogs: {
            notes: {
                delete: false,
            }
        },
        notes: [],
        edited_item: {
            note: {}
        },
        search: ''
    }),

    computed: {

        formTitle() {
            return (this.datatables.notes.edited_index === -1 ? this.$i18n.t("crear_nuevo") : this.$i18n.t("editar")) + " " + this.$i18n.t("nota");
        }

    },

    watch: {
        "dialog.note.edit": function(val) {
            val || this.closeEdit();
        },
        "dialog.note.delete": function(val) {
            val || this.closeDelete();
        },
        "datatables.notes.options": {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {

            this.datatables.notes.headers = [
                { text: this.i18n.t("telefono"), value: "phone" },
                { text: this.i18n.t("comentario"), value: "comment" },
                { text: this.i18n.t("fecha_de_vencimiento"), value: "due_date" },
                { text: this.i18n.t("acciones"), value: "actions", sortable: false }
            ]

            this.setTitle(this.i18n.t("notas"))

        },

        getDataFromApi() {

            this.setLoading(true)

            let _this = this;

            this.$axios({
                    url: "rest/notes",
                    method: "GET",
                    params: _this.datatables.notes.options
                }).then(response => {
                    this.notes = response.data.data
                    this.datatables.notes.total_items = response.data.total
                    this.datatables.notes.page_count = response.data.last_page
                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        deleteItem(item) {

            this.$swal({
                title: this.$t("eliminar"),
                text: this.$t("seguro_deseas_eliminar_registro"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("si"),
                cancelButtonText: this.$t("no")
              })
              .then((result) => {
                if (result.isConfirmed) {
                    this.$axios({
                        url: "/rest/notes",
                        method: "DELETE",
                        params: {
                            id: item.id
                        }
                    })
                    .then(() => {
                        this.getDataFromApi();
                    })
                    .catch(e => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                    });
                }
            });            

        }
        
    },
};