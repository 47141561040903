<template>
  <div>
    <v-app-bar color="blue darken-4" dense dark fixed>

      <v-toolbar-title>{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <v-menu left bottom>

        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar tile>
              {{ (user.name).charAt(0) }}
            </v-avatar>
          </v-btn>
        </template>

        <v-list>
          <v-list-item link>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="signOut()">
            <v-list-item-title>{{ $t("cerrar_sesion") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      abilities: "auth/abilities",
      title: "app/getTitle"
    }),
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),

    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "signin",
        });
      });
    },
  },
};
</script>