import colorHelper from "@/libs/helpers/colorHelper";
import globalDataHelper from "@/libs/helpers/globalDataHelper";
import validationHelper from "@/libs/helpers/validationHelper";
import numberHelper from "@/libs/helpers/numberHelper";
import multimediaHelper from "@/libs/helpers/multimediaHelper";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, History } from 'tiptap-vuetify'

export default {
    mixins: [colorHelper, validationHelper, globalDataHelper, numberHelper, multimediaHelper],
    name: "packs",
    components: {
        TiptapVuetify
    },
    data: () => ({
        datatables: {
            packs: {
                items_per_page: 100
            }
        },
        dialogs: {
            packs: {
                edit: false,
                delete: false,
                start_date: false,
                end_date: false,
                preview: false
            }
        },
        packs: [],
        edited_items: {
            pack: {},
        },
        default_items: {
            pack: {
                status: "programado",
                show_regular_price: true,
                transport_type: 'terrestre',
                increment_rate: 0,
                calendar_format: 'tsv'
            }
        },
        previewed_items: {
            pack: {}
        },
        richTextEditorExtensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList, [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Paragraph,
            HardBreak
        ]
    }),

    computed: {

        packStatuses() {
            return JSON.parse(localStorage.getItem("statuses")).filter(function(
                item
            ) {
                return ["activo", "inactivo"].includes(item.value);
            });
        },

    },

    watch: {

        "dialogs.packs.edit": function(val) {
            val || this.closeEdit();
        },
        "dialog.packs.delete": function(val) {
            val || this.closeDelete();
        },
        "datatables.packs.options": {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        },
        "edited_items.pack.pack_type": function(val) {
            val == "senderismo" || (() => {
                this.edited_items.pack.difficulty_level = null
                this.edited_items.pack.total_distance = null
            })()
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {
            this.datatables.packs.headers = [
                { text: this.i18n.t("informacion"), value: "_titulo_descripcion", sortable: false },
                { text: this.i18n.t("tarifas"), value: "_tarifas", sortable: false },
                { text: this.i18n.t("programacion"), value: "_programacion", sortable: false },
                { text: this.i18n.t("acciones"), value: "_acciones", sortable: false }
            ];

            this.datatables.reservations.headers = [
                { text: this.i18n.t("acciones"), value: "_contenido", sortable: false },
                { text: '', value: 'data-table-expand' }
            ];

            this.default_items.pack.rates = [{
                    "name": this.$i18n.t("adulto"),
                    "value": "0",
                    "seat": true
                },
                {
                    "name": this.$i18n.t("niño"),
                    "value": "0",
                    "seat": true
                }
            ];

            this.default_items.pack.meeting_points = null;
            this.default_items.pack.pictures = null;
            this.default_items.pack.videos = null;

            this.setTitle(this.i18n.t("paquetes"))


        },

        getFormTitle(context) {
            return (
                (this.datatables[context].edited_index === -1 ?
                    this.$i18n.t("crear_nuevo") :
                    this.$i18n.t("editar"))
            );
        },


        getDataFromApi(callback) {

            this.setLoading(true)

            this.$axios({
                    url: "packs",
                    method: "GET",
                    params: this.datatables.packs.options
                }).then((response) => {
                    this.packs = response.data.data
                    this.datatables.packs.total_items = response.data.total
                    this.datatables.packs.page_count = response.data.last_page

                    if (callback) callback()
                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },


        newRate() {
            this.edited_items.pack.rates.push({ "name": "", "value": "0", "seat": true })
        },

        newMeetingPoint() {
            if (this.isEmpty(this.edited_items.pack.meeting_points)) {
                this.edited_items.pack.meeting_points = [];
            }
            this.edited_items.pack.meeting_points.push({ "place": "", "reference_code": "", "time": "00:00", "departure_time": "00:00" })
        },

        newVideo() {
            if (this.isEmpty(this.edited_items.pack.videos)) {
                this.edited_items.pack.videos = [];
            }
            this.edited_items.pack.videos.push({ "title": "", "url": "" })
        },

        setPrimaryPicture(val) {
            for (var i = 0; i < this.edited_items.pack.pictures.length; i++) {
                this.edited_items.pack.pictures[i].primary = false
            }
            this.edited_items.pack.pictures[val].primary = true
        },

        previewPictures() {
            if (this.isEmpty(this.edited_items.pack.pictures)) {
                this.edited_items.pack.pictures = [];
            }

            var fileInput = document.getElementById('image-file-input')
            var selectedFiles = fileInput.files

            if (selectedFiles.length) {
                for (var i = 0; i < selectedFiles.length; i++) {
                    var pictureName = selectedFiles[i].name
                    var reader = new FileReader();

                    reader.onload = (e) => {
                        this.edited_items.pack.pictures.push({
                            "description": pictureName,
                            "url": e.target.result,
                            "primary": false
                        });
                    }
                    reader.readAsDataURL(selectedFiles[i]);
                }
            }
        },


        editItemDialog(item) {
            this.datatables.packs.edited_index = this.packs.indexOf(item);

            if (this.datatables.packs.edited_index == -1) {
                this.edited_items.pack = Object.assign({}, this.default_items.pack)
            } else {
                this.edited_items.pack = Object.assign({}, item);
            }

            this.dialogs.packs.edit = true;
        },

        previewItemDialog(item) {
            this.datatables.packs.preview_index = this.packs.indexOf(item)

            this.previewed_items.pack = Object.assign({}, item)

            this.dialogs.packs.preview = true;
        },

        deleteItemDialog(item) {
            this.datatables.packs.edited_index = this.packs.indexOf(item);
            this.edited_items.pack = Object.assign({}, item);
            this.dialogs.packs.delete = true;
        },

        deleteItem() {

            this.setLoading(true)

            this.$axios({
                    url: "packs",
                    method: "DELETE",
                    params: {
                        id: this.edited_items.pack.id,
                    },
                })
                .then(() => {

                    this.closeDelete();

                    this.getDataFromApi();

                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        closePreview() {
            this.dialogs.packs.preview = false;
            this.$nextTick(() => {
                this.previewed_items.pack = Object.assign({}, this.default_items.pack)
                this.datatables.packs.preview_index = -1;
            });
        },

        closeEdit() {
            this.dialogs.packs.edit = false;
            this.$nextTick(() => {
                this.edited_items.pack = Object.assign({}, this.default_items.pack)
                this.datatables.packs.edited_index = -1;
            });
        },

        closeDelete() {
            this.dialogs.packs.delete = false;
            this.$nextTick(() => {
                this.edited_items.pack = Object.assign({}, this.default_items.pack)
                this.datatables.packs.edited_index = -1;
            });
        },

        addEvent(pack) {

            this.setLoading(true)

            this.$axios({
                    url: "events",
                    method: "PUT",
                    data: pack,
                })
                .then((response) => {

                    this.getDataFromApi();

                    this.closeEdit();

                    this.$router.push({
                        name: 'events',
                        query: {
                            pack_id: response.data.data.pack_id,
                            edit_event_id: response.data.data.id
                        }
                    })

                })
                .catch((e) => {
                    console.log(e)
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        saveItem() {

            this.validation_errors = null
            this.setLoading(true)

            if (this.datatables.packs.edited_index > -1) {

                this.$axios({
                        url: "packs",
                        method: "PATCH",
                        params: {
                            id: this.edited_items.pack.id,
                        },
                        data: this.edited_items.pack,
                    })
                    .then(() => {

                        this.getDataFromApi();

                        this.closeEdit();

                    })
                    .catch((e) => {
                        this.validation_errors = this.getValidationErrorsHtml(e);
                    })
                    .finally(() => {
                        this.setLoading(false)
                    });

            } else {

                this.setLoading(true)

                if (this.isEmpty(this.edited_items.pack.status)) {
                    this.edited_items.pack.status = "programado";
                }

                this.$axios({
                        url: "packs",
                        method: "PUT",
                        data: this.edited_items.pack,
                    })
                    .then(() => {

                        this.closeEdit();

                        this.validation_errors = null;

                        this.getDataFromApi();

                    })
                    .catch((e) => {
                        this.validation_errors = this.getValidationErrorsHtml(e);
                    })
                    .finally(() => {
                        this.setLoading(false)
                    });

            }
        }

    },

};