<template>
  <div>
    <v-expansion-panels class="pa-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Búsqueda
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense class="mb-0 pb-0">
            <v-col cols="6" md="7">
              <v-text-field
                v-model="datatables.sales.options.search"
                append-icon="mdi-magnify"
                :label="$t('ingrese_terminos_busqueda')"
                single-line
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="3" md="3">
              <v-select
                v-model="datatables.sales.options.bus_number"
                :label="i18n.t('bus_numero')"
                :items="getBusList(true)"
                item-text="display"
                item-value="value"
                dense
              >
              </v-select>
            </v-col>

            <v-col cols="3" md="2">
              <v-checkbox
                outlined
                v-model="datatables.sales.options.show_cancelled"
                :label="i18n.t('mostrar_cancelados')"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-data-table
      :search="datatables.sales.options.search"
      :page.sync="datatables.sales.page"
      :items-per-page="datatables.sales.items_per_page"
      :options.sync="datatables.sales.options"
      :server-items-length="datatables.sales.total_items"
      :headers="datatables.sales.headers"
      :items="reservation_groups"
      class="elevation-1 pb-5 mx-3"
      item-key="id"
      hide-default-footer
      mobile-breakpoint="0"
      show-expand
      :expanded.sync="expanded"
      full-width
    >
      <template v-slot:top>
        <v-dialog v-model="dialogs.reservations.delete" max-width="600px">
          <v-card>
            <v-card-title class="headline">{{
              $t("seguro_deseas_eliminar_registro")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="save-button darken-1" @click="closeDelete()">{{
                $t("cancelar")
              }}</v-btn>
              <v-btn
                dark
                color="cancel-button darken-1"
                @click="deleteItem()"
                >{{ $t("eliminar") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.clients.profile" persistent>
          <ClientProfile :client="previewed_items.client">
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                color="cancel-button darken-1"
                @click="closeClientProfileDialog()"
              >
                {{ $t("cancelar") }}
              </v-btn>
            </template>
          </ClientProfile>
        </v-dialog>
      </template>

      <template v-slot:no-data>
        {{ $t("sin_datos") }}
      </template>

      <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
        <v-icon @click="handleExpansion(item, isExpanded)">{{
          isExpanded ? "mdi-folder-open" : "mdi-folder"
        }}</v-icon>
      </template>

      <template v-slot:[`item.main_client`]="{ item }">
        {{ getMainClient(item) }}
      </template>

      <template v-slot:[`item.options`]="{ item }">
        <span v-if="canPrintVoucher(item)">
          <v-icon
            class="mr-2 edit-button--text"
            :title="$t('ver_comprobante')"
            @click="getGroupVoucher(item)"
          >
            mdi-file-download</v-icon
          >

          <v-icon
            class="mr-2"
            color="red"
            :title="$t('descargar_comprobante')"
            @click="showGroupVoucher(item)"
          >
            mdi-file-pdf</v-icon
          >

          <v-icon
            class="mr-2"
            color="green"
            :title="$t('copiar_enlace_del_comprobante')"
            @click="copyVoucherLink(item)"
          >
            mdi-file-pdf</v-icon
          >
        </span>

        <v-icon
          class="mr-2"
          color="orange"
          :title="$t('hay_problemas_con_este_grupo')"
          v-if="!canPrintVoucher(item)"
        >
          mdi-alert</v-icon
        >

        <v-icon
          class="mr-2 delete-button--text"
          color="red"
          :title="$t('eliminar')"
          @click="deleteSale(item)"
          v-if="item.reservations_count == 0 && item.payments_count == 0"
        >
          mdi-delete</v-icon
        >
      </template>

      <template v-slot:[`item.user_count`]="{ item }">
        {{ item.active_reservations_count }}
      </template>

      <template v-slot:[`item.total_amount`]="{ item }">
        {{ formatCurrency(item.total_amount) }}
      </template>

      <template v-slot:[`item.paid_amount`]="{ item }">
        <v-icon
          class="mr-2"
          color="red"
          :title="$t('saldo_incoherente')"
          v-if="item.total_amount < item.paid_amount"
        >
          mdi-alert</v-icon
        >
        <span
          :style="{
            color: item.total_amount < item.paid_amount ? 'red' : 'inherit',
          }"
        >
          {{ formatCurrency(item.paid_amount) }}
        </span>
      </template>

      
      <template v-slot:[`item.balance_amount`]="{ item }">
        <span>
          {{ formatCurrency(item.total_amount - item.paid_amount) }}
        </span>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="100">
          <v-simple-table class="expanded-item-table">
            <thead>
              <tr>
                <th>{{ $t("estado") }}</th>
                <th>{{ $t("cliente") }}</th>
                <th>{{ $t("opciones") }}</th>
                <th>{{ $t("documento") }}</th>
                <th>{{ $t("telefono") }}</th>
                <th>{{ $t("edad") }}</th>
                <th>{{ $t("valor") }}</th>
                <th>{{ $t("encuentro") }}</th>
                <th v-if="event.pack_type == 'excursion'">
                  {{ $t("acomodacion") }}
                </th>
                <th>{{ $t("silla") }}</th>
                <th>{{ $t("observaciones") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="reservation in item.reservations"
                :key="reservation.id"
                :style="
                  'background-color:' + reservation.status == 'activo'
                    ? 'green'
                    : 'red'
                "
              >
                <td>
                  <v-checkbox
                    color="green"
                    :input-value="reservation.status == 'activo'"
                    @change="updateReservation($event, 'status', reservation)"
                    :disabled="reservation.value == 0"
                  ></v-checkbox>
                </td>

                <td>
                  <v-icon
                    small
                    class="mr-1"
                    :color="
                      getClientRatingColor(reservation.client.reservation_count)
                    "
                    :title="
                      reservation.client.reservation_count +
                        ' ' +
                        $t('reservas')
                    "
                  >
                    mdi-disc</v-icon
                  >
                  <span
                    :title="reservation.id"
                    class="link"
                    @click="previewClientProfileDialog(reservation.client)"
                    >{{ reservation.client.name }}
                  </span>
                </td>
                <td>
                  <v-icon
                    :title="$t('cancelar_reserva')"
                    color="orange"
                    @click="
                      updateReservation('cancelado', 'status', reservation)
                    "
                    v-if="reservation.status == 'borrador'"
                    :disabled="reservation.status == 'activo'"
                  >
                    mdi-close-circle</v-icon
                  >
                  <v-icon
                    class="delete-button--text"
                    :title="$t('eliminar')"
                    color="red"
                    @click="deleteReservation(reservation)"
                    v-if="reservation.status == 'cancelado'"
                  >
                    mdi-delete</v-icon
                  >
                </td>
                <td>
                  {{ reservation.client.document }}
                </td>
                <td>
                  {{ reservation.client.phone?'+'+reservation.client.country.dialing_code +''+ reservation.client.phone:''}}
                  {{ reservation.client.phone_alternative }}
                </td>
                <td>
                  {{
                    reservation.client.age_y
                      ? reservation.client.age_y + "a"
                      : ""
                  }}{{
                    reservation.client.age_m
                      ? reservation.client.age_m + "m"
                      : ""
                  }}
                </td>
                <td>
                  <v-combobox
                    item-text="value"
                    item-value="value"
                    :items="event.rates"
                    solo
                    flat
                    :value="reservation.value"
                    class="rate-combobox"
                    hide-details
                    @change="updateReservation($event, 'value', reservation)"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" :v-attrs="attrs">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }} | {{ item.value }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template
                      v-slot:selection="{ item }"
                      style="text-align:center"
                    >
                      <span class="d-flex justify-center" style="width: 100%;">
                        {{ formatCurrency(item.value || item) }}
                      </span>
                    </template>
                  </v-combobox>
                </td>

                <td>
                  <v-select
                    item-text="reference_code"
                    item-value="reference_code"
                    :items="event.meeting_points"
                    solo
                    flat
                    hide-details
                    :value="reservation.meeting_point"
                    class="rate-combobox"
                    @change="
                      updateReservation($event, 'meeting_point', reservation)
                    "
                    :disabled="reservation.value == 0"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" :v-attrs="attrs">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.reference_code }} {{ item.rime }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template
                      v-slot:selection="{ item }"
                      style="text-align:center"
                    >
                      <span class="d-flex justify-center" style="width: 100%;">
                        {{ item.reference_code }}
                      </span>
                    </template>
                  </v-select>
                </td>

                <td v-if="event.pack_type == 'excursion'">
                  <v-select
                    item-text="text"
                    item-value="value"
                    :items="accom_types"
                    solo
                    flat
                    hide-details
                    :value="reservation.accom_type"
                    class="rate-combobox"
                    @change="
                      updateReservation($event, 'accom_type', reservation)
                    "
                    :disabled="reservation.value == 0"
                  >
                    <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" :v-attrs="attrs">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>

                    <template
                      v-slot:selection="{ item }"
                      style="text-align:center"
                    >
                      <span class="d-flex justify-center" style="width: 100%;">
                        {{ item.text }}
                      </span>
                    </template>
                  </v-select>
                </td>

                <td>
                  <v-checkbox
                    :input-value="reservation.seat == 1"
                    @change="updateReservation($event, 'seat', reservation)"
                    :disabled="reservation.value == 0"
                  ></v-checkbox>
                </td>

                <td>
                  <v-hover v-slot="{ hover }">
                    <v-text-field
                      solo
                      :flat="hover ? false : true"
                      hide-details
                      :value="reservation.notes"
                      @change="updateReservation($event, 'notes', reservation)"
                    ></v-text-field>
                  </v-hover>
                </td>
              </tr>
              <tr>
                <td colspan="100" class="pa-3">
                  <v-expansion-panels multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        {{ $t("adicionar_usuario") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="8" md="10">
                            <v-combobox
                              v-model="edited_items.new_client"
                              item-text="name"
                              item-value="id"
                              :items="clients"
                              :label="$t('cliente')"
                              outlined
                              :search-input.sync="datatables.clients.search"
                              no-filter
                              dense
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      🔎
                                      {{
                                        $t("sin_resultados", [
                                          datatables.clients.search,
                                        ])
                                      }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>

                              <template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" :v-attrs="attrs">
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.name }} | {{ item.document }}
                                      {{ item.email }} {{ item.phone }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="4" md="2">
                            <v-btn color="success" @click="addClient(item)">
                              {{ $t("guardar") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="item.payments.length">
                      <v-expansion-panel-header>
                        {{ $t("lista_de_pagos") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <PaymentList
                          :payments="item.payments"
                          @payment-deleted="getDataFromApi()"
                        ></PaymentList>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel
                      v-if="item.paid_amount < item.total_amount"
                    >
                      <v-expansion-panel-header>
                        {{ $t("registrar_nuevo_pago") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <PaymentForm
                          :sale="item"
                          @payment-added="getDataFromApi()"
                        ></PaymentForm>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>

      <!--<template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="item.id">
              <td class="reservation-row">
                <v-container class="pl-6 py-4" fill-height fluid :style="'background-color:' + item.group_color">                

                  <v-row align="center">

                    <v-col cols="6" sm="12" md="1" class="row-number-column">
                      <label class="row-number">{{ getReservationNumber(index).toString() }}</label>                    
                    </v-col>


                    <v-col cols="6" sm="6" md="4">                    
                      <div 
                        class="mb-0 reservation-main-text"
                      >
                          <v-icon
                              small
                              class="mr-1"
                              :color="getClientRatingColor(item.client.reservation_count)"
                              :title="item.client.reservation_count + ' ' + $t('reservas')"
                          >
                          mdi-disc</v-icon
                          >                
                          <span
                              :title="item.id"                      
                              class="client-name"
                              @click="previewClientProfileDialog(item.client)"
                              >{{ item.client.document }} | {{ item.client.name }}
                          </span> 

                      </div>
                      <span class="subheading-1">
                        {{ item.client.age_y?item.client.age_y + ' ' +  $t('años'):'' }} {{ item.client.age_m?item.client.age_m + ' ' + $t('meses'):'' }}
                      </span>
                    </v-col>

                    
                    <v-col cols="6" sm="6" md="4">
                      <v-container fill-height fluid >
                        <v-row align="center">
                          <v-col cols="12" md="3">
                            <div class="mb-0">
                              {{ item.client.phone }}
                            </div>
                            <div class="subheading-1">
                              {{ item.client.phone_alternative }}
                            </div>
                          </v-col>

                          <v-col cols="12" md="5">
                            <div class="mb-0">
                              {{ formatCurrency(item.value) }} | 
                              {{ formatCurrency(item.pending_balance) }}
                            </div>
                            <div
                              class="subheading-1"
                              v-if="
                                item.accom_type && item.accom_type != 'no_aplica'
                              "
                            >
                              {{
                                $t("acomodacion") +
                                " " +
                                $t("tipos_de_acomodacion." + item.accom_type)
                              }}
                            </div>
                          </v-col>

                          <v-col cols="12" sm="12" md="4">
                            <div class="mb-0">
                              <i>{{ item.notes }}</i>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>

                    <v-col class="d-none d-md-block" cols="12" md="3">
                      <div class="float-right full-width">

                        <v-icon
                          class="mr-2"
                          color="red"
                          v-if="item.has_group"
                          :title="$t('grupo')"
                        >
                          mdi-home-group</v-icon
                        >

                        <v-icon
                          class="mr-2"
                          color="black"
                          v-if="!item.seat"
                          :title="$t('ocupa_silla')"
                        >
                          mdi-baby-carriage</v-icon
                        >

                        <v-icon
                          class="mr-2 edit-button--text"
                          v-if="item.status == 'activo' && item.has_group && event.id"
                          :title="$t('ver_comprobante')"
                          @click="getGroupVoucher(item)"
                        >
                        mdi-file-download</v-icon
                        >

                        <v-icon
                          class="mr-2"
                          color="red"
                          v-if="item.status == 'activo' && item.has_group && event.id"
                          :title="$t('descargar_comprobante')"
                          @click="showGroupVoucher(item)"
                        >
                          mdi-file-pdf</v-icon
                        >

                        <v-icon
                          class="mr-2"
                          color="green"
                          v-if="item.status == 'activo' && item.has_group && event.id"
                          :title="$t('descargar_comprobante')"
                          @click="copyVoucherLink(item)"
                        >
                          mdi-file-pdf</v-icon
                        >

                        <v-icon
                          class="mr-2"
                          color="green"
                          v-if="item.status == 'activo'  || item.status == 'borrador'"
                          :title="$t('pagos')"
                          @click="editPaymentDialog(item)"
                        >
                          mdi-currency-usd</v-icon
                        >

                        <v-icon                        
                          class="mr-2"
                          color="red"
                          v-if="item.status == 'borrador'"
                          :title="$t('sin_confirmar')"
                        >
                          mdi-exclamation-thick</v-icon
                        >
                        <v-icon
                          class="mr-2 edit-button--text"
                          :title="$t('editar')"
                          color="green"
                          @click="editItemDialog(item)"
                        >
                          mdi-file-document-edit</v-icon
                        >
                        <v-icon
                          class="delete-button--text"
                          :title="$t('eliminar')"
                          @click="deleteItemDialog(item)"
                          v-if="item.status == 'cancelado'"
                          :disabled="!isEmpty(item.deleted_at)"                   
                        >
                          mdi-delete
                        </v-icon>

                      </div>
                    </v-col>                
                  </v-row>             

                  
                </v-container>
              </td>
            </tr>
          </tbody>
        </template> -->
    </v-data-table>

    <v-pagination
      v-model="datatables.sales.page"
      :length="datatables.sales.page_count"
      v-if="datatables.sales.total_items > 0 && datatables.sales.page_count > 1"
    ></v-pagination>

    <v-speed-dial
      v-model="dialogs.fabs.tools"
      v-show="event.id > 0"
      :top="false"
      :bottom="true"
      :right="true"
      :left="false"
      direction="top"
      :open-on-hover="true"
      transition="slide-y-reverse-transition"
      class="floating-menu-button"
    >
      <template v-slot:activator>
        <v-btn
          v-model="dialogs.fabs.tools"
          :color="event.status == 'programado' ? 'blue darken-2' : 'grey'"
          dark
          fab
          class="fab-custom"
          @click="addSale()"
        >
          <v-icon>
            mdi-creation
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green" @click="getEventSheet()">
        <v-icon>mdi-file-excel</v-icon>
      </v-btn>
      <v-btn fab dark small color="blue" @click="getEventInsuranceSheet()">
        <v-icon>mdi-file-excel</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<style scoped src="@/views/Reservation/Reservations.css"></style>

<script>
import reservation from "@/views/Reservation/Reservations";
export default reservation;
</script>
