<template>
  <div>
    <v-data-table
      :headers="datatables.users.headers"
      :items="users"
      :options.sync="datatables.users.options"
      :server-items-length="datatables.users.total"
      sort-by="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("usuarios") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogs.user.edit"  max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="edited_items.user.name"
                        :label="i18n.t('nombre')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="edited_items.user.email"
                        :label="i18n.t('email')"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="edited_items.user.document"
                        :label="i18n.t('documento')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="edited_items.user.edit_password"
                        :label="i18n.t('modificar_contraseña')"
                        :readonly="datatables.users.edited_index == -1"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="edited_items.user.edit_password"  sm="6">
                      <v-text-field
                        id="password"
                        v-model="edited_items.user.new_password"
                        :label="i18n.t('contraseña')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="edited_items.user.edit_password" sm="6">
                      <v-text-field
                        id="password_2"
                        v-model="edited_items.user.new_confirm_password"
                        :label="i18n.t('contraseña')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="edited_items.user.role"
                        :label="i18n.t('rol')"
                        :items="roles"
                        item-text="text"
                        item-value="value"
                      >
                    </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="edited_items.user.status"
                        :label="i18n.t('estado')"
                        :items="statuses"
                        item-text="text"
                        item-value="value"
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="validation_errors">
                    <v-col>
                      <span v-html="validation_errors"></span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="cancel-button darken-1"  @click="closeEdit"> {{ $t("cancelar")}} </v-btn>
                <v-btn dark color="save-button darken-1"  @click="saveItem"> {{ $t("guardar")}} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogs.user.delete" max-width="300px">
            <v-card>
              <v-card-title class="headline"
                >{{ $t("seguro_deseas_eliminar_registro") }}</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >{{ $t("cancelar")}}</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItem"
                  >{{ $t("eliminar") }}</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon large class="mr-2 edit-button--text" @click="editItemDialog(item)"> mdi-file-document-edit</v-icon>
        <v-icon large class="delete-button--text" @click="deleteItemDialog(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("sin_datos")}}
      </template>

      <template v-slot:[`item.role`]="{ item }">
        {{ $t("roles." + item.role) }}
      </template>
      
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="getStatusColor(item.status)"
          dark
        >
          {{ $t("estados." + item.status) }}
        </v-chip>
      </template>

    </v-data-table>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="true"
          color="blue"
          dark
          fixed
          bottom
          right
          class="fab-custom"
          fab
          @click="editItemDialog()"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-creation</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("nuevo") }}</span>
    </v-tooltip>
  </div>  
</template>


<script>
  import user from '@/views/User/Users'
  export default user
</script>
