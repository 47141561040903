<template>
    <v-card>
            
        <v-card-title class="headline text-center">
            {{ client.name }}
        </v-card-title>
        <v-card-text> 
            <v-container>
                <v-row>
                    <v-col cols="12">

                        <v-simple-table dense>
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('fecha_de_creacion') }}</td>
                                        <td>{{ client.created_at }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('documento') }}</td>
                                        <td>{{ client.document }}</td>
                                    </tr>                                        
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('fecha_de_nacimiento') }}</td>
                                        <td>{{ client.birthday }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('telefono') }}</td>
                                        <td>{{ client.phone }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('telefono_alternativo') }}</td>
                                        <td>{{ client.phone_alternative }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('email') }}</td>
                                        <td>{{ client.email }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('sexo') }}</td>
                                        <td>{{ $i18n.t('sexos.' + client.sex ) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left subtitle-2">{{ $i18n.t('tags') }}</td>
                                        <td>
                                            <v-chip
                                            small
                                            class="my-1 mr-1"
                                            :color="tag.color"
                                            text-color="white"
                                            v-for="(tag, index) in client.tags" :key="index"
                                            >
                                            {{ tag.name }}
                                            </v-chip>
                                        </td>
                                    </tr>
                                </tbody>                                
                            </template>
                        </v-simple-table>

                        <v-spacer><br/></v-spacer>

                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left subtitle-2">{{ $i18n.t('fecha') }}</th>
                                        <th class="text-left subtitle-2">{{ $i18n.t('actividad') }}</th>
                                        <th class="text-left subtitle-2">{{ $i18n.t('estado') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(r, index) in client.reservations"
                                        :key="index"
                                    >
                                        <td>{{ r.event.start_date }}</td>
                                        <td>{{ r.event.pack.name }}</td>
                                        <td>{{ $i18n.t('estados.' + r.status) }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>                            
                        
                    </v-col>                  
                </v-row>        
            </v-container>
        </v-card-text>
        <v-card-actions style="position: sticky; bottom: 0">
            <slot name="actions"></slot>
        </v-card-actions>
    </v-card>        
</template>

<script>
export default ({
    name: "Profile",
    props: {
        client: Object
    }    
})
</script>
