import { mapActions } from 'vuex';

export default {
    data: () => ({
        datatables: {
            packs: {
                options: {},
                headers: [],
                total_items: 0,
                edited_index: -1,
                preview_index: -1
            },
            users: {
                options: {},
                headers: [],
                total_items: 0,
                edited_index: -1
            },
            clients: {
                options: {},
                headers: [],
                total_items: 0,
                edited_index: -1
            },
            reservations: {
                options: {},
                headers: [],
                total_items: 0,
                edited_index: -1
            },
            events: {
                options: {},
                headers: [],
                total_items: 0,
                edited_index: -1
            }
        },
        edited_items: {},
        default_items: {},
        selected_items: {},

        array_columns: [],

        roles: JSON.parse(localStorage.getItem("roles")),
        sexes: JSON.parse(localStorage.getItem("sexes")),
        pack_types: JSON.parse(localStorage.getItem("pack_types")),
        accom_types: JSON.parse(localStorage.getItem("accom_types")),
        agencies: JSON.parse(localStorage.getItem("agencies")),
        validation_errors: null

    }),

    methods: {
        ...mapActions({
            setLoading: "app/setLoading",
            setTitle: "app/setTitle"
        })
    }
}