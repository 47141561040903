export default {
    methods: {
        jsonColumnToArray(datasource) {
            this.array_columns.forEach(column => {
                (datasource).forEach(item => {
                    if (typeof item[column] == 'string') {
                        item[column] = JSON.parse(item[column])
                    }
                });
            })
        }
    }
}