import numberHelper from "@/libs/helpers/numberHelper.js";
import globalDataHelper from "@/libs/helpers/globalDataHelper.js";
import validationHelper from "@/libs/helpers/validationHelper.js";

export default {
  props: {
    payments: Array,
  },
  components: {  },
  mixins: [numberHelper, globalDataHelper, validationHelper],
  computed: {
    allPayments: {
      get() {
        return this.payments
      }
    }
  },
  methods: { 
    deletePayment(payment) {
      
      this.setLoading(true);

      this.validation_errors = null;

      this.$swal({
        title: this.$t("eliminar"),
        text: this.$t("seguro_deseas_eliminar_registro"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("si"),
        cancelButtonText: this.$t("no")
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.$axios({
            url: "payments",
            method: "DELETE",
            data: {
              id: payment.id,
              sale_id: payment.sale_id
            },
          })
            .then(() => {
              this.validation_errors = null;
            })
            .catch((e) => {
              this.validation_errors = this.getValidationErrorsHtml(e);
            })
            .finally(() => {
              this.setLoading(false);
              this.$emit('payment-deleted');
            });
        }
    });        
      
      
      
    },
   },
};
