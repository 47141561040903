import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { abilitiesPlugin } from '@casl/vue'
import { Can } from '@casl/vue'
import vuetify from '@/plugins/vuetify'

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/custom.css'

import 'sweetalert2/dist/sweetalert2.min.css'

require('@/store/plugins/tokenSuscriber')

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

Vue.config.productionTip = false
Vue.use(abilitiesPlugin, store.getters.ability)
Vue.component('Can', Can)

import i18n from '@/plugins/i18n'; 
Vue.use(i18n)
Vue.prototype.i18n = i18n

import axios from 'axios'
Vue.prototype.$axios = axios;

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2);

Vue.prototype.$public_url = window.location.origin + "/";

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'fa'
})

store.dispatch(
    'auth/attempt', 
    localStorage.getItem('token')
  ).then( () => {

    new Vue({
      i18n,
      vuetify,
      el: "#app",
      router: router,
      render: h => h(App),
      store,
      components: {
        'Can': Can
      }
    })
    
  })

