<template>
  <div>
    <v-expansion-panels class="px-3 pb-3" v-model="expanded_panels">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Búsqueda
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row  class="px-2">
            <v-col cols="6" md="3" >
              <v-combobox
                :label="$t('paquete')"
                dense
                v-model="selected_pack"
                :items="packs"
                item-text="name"
                item-value="id"
              ></v-combobox>       
            </v-col>
            <v-col cols="6" md="3" >
              <v-text-field
                :label="$t('desde') + '...'"
                v-model="datatables.events.options.date_from"
                dense
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                :label="$t('hasta') + '...'"
                v-model="datatables.events.options.date_to"
                dense
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
              <v-select
                :label="$t('estado')"
                dense
                v-model="datatables.events.options.status"
                :items="eventStatuses"
                item-text="text"
                item-value="value"
                multiple
                disable-lookup
              ></v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <v-pagination
      v-model="datatables.events.page"
      :length="datatables.events.page_count"
      v-if="datatables.events.total_items > 0 && datatables.events.page_count > 1"
    ></v-pagination>

    <v-data-table
      :headers="datatables.events.headers"
      :items="events"
      :options.sync="datatables.events.options"
      :server-items-length="datatables.events.total_items"
      sort-by="name"
      class="elevation-1"
      item-key="id"
      hide-default-header
      hide-default-footer
      :search="datatables.events.options.search"
      :page.sync="datatables.events.page"
      :items-per-page="datatables.events.items_per_page"
    >
      <template v-slot:top>

        <v-dialog 
          v-model="dialogs.events.edit" 
          persistent
          fullscreen
        >
          <v-card>
            <v-card-title class="headline">
              {{ getFormTitle("packs") }}  {{ $i18n.t("paquete") }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="edited_items.event.name"
                      :label="i18n.t('nombre')"
                    >
                    </v-text-field>
                  </v-col>                       

                  <v-col cols="12" md="3">
                    <v-select
                      outlined
                      v-model="edited_items.event.status"
                      :label="i18n.t('estado')"
                      :items="eventStatuses"
                      item-text="text"
                      item-value="value"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select
                      outlined
                      v-model="edited_items.event.agency_id"
                      :label="i18n.t('agencia')"
                      :items="agencies"
                      item-text="text"
                      item-value="value"
                      :return-object="false"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.event.short_description"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('descripcion_corta') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>                 

                  <v-col cols="12" md="6">
                    <v-select
                      outlined
                      v-model="edited_items.event.pack_type"
                      :label="i18n.t('tipo_de_paquete')"
                      :items="pack_types"
                      item-text="text"
                      item-value="value"
                      disabled
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      outlined
                      v-model="edited_items.event.bus_count"
                      :label="i18n.t('numero_de_buses')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" v-if="edited_items.event.pack_type=='senderismo'">
                    <v-select
                      outlined
                      v-model="edited_items.event.difficulty_level"
                      :label="i18n.t('nivel_de_dificultad')"
                      :items="[
                        '1',
                        '1.5',
                        '2',
                        '2.5',
                        '3',
                        '3.5',
                        '4',
                        '4.5',
                        '5'
                      ]"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" v-if="edited_items.event.pack_type=='senderismo'">
                    <br>
                    <v-slider
                      v-model="edited_items.event.total_distance"
                      :label="i18n.t('distancia_total')"
                      thumb-color="blue"
                      thumb-label="always"
                      min="1"
                      max="50"
                    ></v-slider>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.event.includes"
                      :extensions="richTextEditorExtensions"
                    >
                      <template #toolbar-before><label>{{ i18n.t('incluye') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.event.not_includes"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('no_incluye') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.event.itinerary"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('itinerario') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col> 

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.event.observations"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('observaciones') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>   

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.event.recommendations"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('recomendaciones') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col> 

                  <v-col cols="12" sm="6" lg="2">
                    <v-text-field
                      outlined
                      v-model="edited_items.event.min_quota"
                      :label="i18n.t('cupo_minimo')"
                      type="number"
                      min="1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" lg="2">
                    <v-text-field
                      outlined
                      v-model="edited_items.event.max_quota"
                      :label="i18n.t('cupo_maximo')"
                      type="number"
                      max="500"
                      :min="edited_items.event.min_quota"
                    ></v-text-field>
                  </v-col>  

                  <v-col cols="12" sm="6" lg="4">
                    <v-dialog
                      ref="start_date"
                      v-model="dialogs.events.pack_start_date"
                      :return-value.sync="edited_items.event.start_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="edited_items.event.start_date"
                          :label="i18n.t('inicio')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="edited_items.event.start_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogs.events.pack_start_date = false"
                        >
                          {{ i18n.t("cancelar") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.start_date.save(edited_items.event.start_date)"
                        >
                          {{ i18n.t("aplicar") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col> 

                  <v-col cols="12" sm="6" lg="4">
                    <v-dialog
                      ref="end_date"
                      v-model="dialogs.events.pack_end_date"
                      :return-value.sync="edited_items.event.end_date"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="edited_items.event.end_date"
                          :label="i18n.t('fin')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="edited_items.event.end_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogs.events.pack_end_date = false"
                        >
                          {{ i18n.t("cancelar") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.end_date.save(edited_items.event.end_date)"
                        >
                          {{ i18n.t("aplicar") }}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12">                   
                    <v-btn color="primary" dark class="mb-2" @click="newRate()">
                      <v-icon>mdi-creation</v-icon><span>{{ $t("nueva_tarifa") }}</span>
                    </v-btn>
                  </v-col>

                  <v-container fluid class="container-bg px-10 py-10 rounded-lg">
                    <v-row
                        v-for="(rate, index) in edited_items.event.rates" 
                        v-bind:key="index">
                        <v-col cols="12" md="5">
                          <v-text-field
                            outlined
                            v-model="rate.name"
                            :label="i18n.t('tarifa')"
                            type="text"
                            maxlength="30"
                            counter="30"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="9" md="2">
                          <v-text-field
                            outlined
                            v-model="rate.value"
                            :label="i18n.t('valor')"
                            type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3" md="2">
                          <v-checkbox
                            outlined
                            v-model="rate.seat"
                            :label="i18n.t('ocupa_silla')"
                            type="number"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="10" md="2">
                          <v-select
                            outlined
                            v-model="rate.accom_type"
                            :label="i18n.t('acomodacion')"
                            item-value="value" 
                            item-text="text" 
                            :items="accom_types"
                            :disabled="edited_items.event.pack_type!='excursion'"
                          ></v-select>
                        </v-col>

                        <v-col cols="2" md="1">
                          <v-icon
                            @click="edited_items.event.rates.splice(index, 1)"
                            large
                            class="delete-button--text mt-3 float-right"
                          >mdi-delete
                          </v-icon>
                        </v-col>
                        
                    </v-row>

                    <v-row v-if="isEmpty(edited_items.event.rates)">
                      {{ $t("sin_tarifas_clic_crea_una") }}
                    </v-row>
                  </v-container>                        

                  <v-col cols="12">
                    <br>
                  </v-col>

                  <v-col cols="12">                   
                    <v-btn color="primary" dark class="mb-2" @click="newMeetingPoint()">
                      <v-icon>mdi-creation</v-icon><span>{{ $t("nuevo_punto_de_encuentro") }}</span>
                    </v-btn>
                  </v-col>  

                  <v-container fluid class="container-bg px-10 py-10 rounded-lg">
                    <v-row
                        v-for="(meeting_point, index) in edited_items.event.meeting_points" 
                        v-bind:key="index">
                        
                        <v-col cols="12" md="10">
                          <v-text-field
                            outlined
                            v-model="meeting_point.place"
                            :label="i18n.t('lugar')"
                            type="text"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-text-field
                            outlined
                            v-model="meeting_point.reference_code"
                            :label="i18n.t('referencia')"
                            type="text"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col md="5" cols="12">
                          <v-text-field
                            outlined
                            v-model="meeting_point.time"
                            :label="i18n.t('hora_de_encuentro')"
                            type="time"
                          ></v-text-field>
                        </v-col>

                        <v-col md="5" cols="10">
                          <v-text-field
                            outlined
                            v-model="meeting_point.departure_time"
                            :label="i18n.t('hora_de_salida')"
                            type="time"
                          ></v-text-field>
                        </v-col>

                        <v-col md="2" cols="2">
                          <v-icon
                            @click="edited_items.event.meeting_points.splice(index, 1)"
                            large class="delete-button--text mt-3 float-right"
                          >mdi-delete
                          </v-icon>
                        </v-col>
                    </v-row>

                    <v-row v-if="isEmpty(edited_items.event.meeting_points)">
                      {{ $t("sin_puntos_de_encuentro_clic_crea_uno") }}
                    </v-row>

                  </v-container>

                  <v-col cols="12">
                    <br>
                  </v-col>

                </v-row>                    

                <v-row v-if="validation_errors">
                  <v-col>
                    <span v-html="validation_errors"></span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions style="position: sticky; bottom: 0">
              <v-spacer></v-spacer>
              <v-btn dark color="cancel-button darken-1" @click="closeEdit()">
                {{ $t("cancelar") }}
              </v-btn>
              <v-btn dark color="save-button darken-1" @click="saveItem()">
                {{ $t("guardar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> 

        <v-dialog 
          v-model="dialogs.events.preview" 
          fullscreen
        >
          <v-card>
            <v-card-title class="headline">
              {{ $i18n.t("detalles_de") }} {{ previewed_items.event.name }}<br>
              <v-chip
                  class="ma-2"
                  :color="getStatusColor(previewed_items.event.status)"
                  dark
                  >
                {{ previewed_items.event.status?i18n.t("estados." + previewed_items.event.status):'' }}
              </v-chip>
        
            </v-card-title>

            <v-card-text>
              <v-container 
                class="element-preview">
                <v-row>

                  <v-col cols="12">
                    <h6>{{ $i18n.t("descripcion_corta") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.event.short_description"></div>

                    <h6>{{ $i18n.t("tipo_de_paquete") }}:</h6> 
                    <div class="preview-item-property-value">{{ previewed_items.event.id?$i18n.t("tipos_de_paquetes." + previewed_items.event.pack_type):'' }}</div>
                    
                    <div v-if="previewed_items.event.pack_type && previewed_items.event.pack_type=='senderismo'">
                      <h6>{{ $i18n.t("nivel_de_dificultad") }}:</h6> 
                      <div class="preview-item-property-value">{{ previewed_items.event.difficulty_level }}</div>
                    </div>

                    <div v-if="previewed_items.event.pack_type && previewed_items.event.pack_type=='senderismo'">
                      <h6>{{ i18n.t('distancia_total') }}</h6>
                      <div class="preview-item-property-value">{{ previewed_items.event.total_distance }} km.</div>
                    </div>

                    <h6>{{ $i18n.t("incluye") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.event.includes"></div>

                    <h6>{{ $i18n.t("no_incluye") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.event.not_includes"></div>
                    
                    <h6>{{ $i18n.t("observaciones") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.event.observations"></div>
                    
                    <h6>{{ $i18n.t("recomendaciones") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.event.recommendations"></div>
                    
                    <h6>{{ $i18n.t("inicio") }}:</h6> 
                    <div class="preview-item-property-value">{{ previewed_items.event.start_date }}</div>
                    
                    <h6>{{ $i18n.t("fin") }}:</h6> 
                    <div class="preview-item-property-value">{{ previewed_items.event.end_date }}</div>
                    
                    
                    <h6>{{ $i18n.t("tarifas") }}:</h6>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ i18n.t('tarifa') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('valor') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('ocupa_silla') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(rate, index) in previewed_items.event.rates"
                            :key="index"
                          >
                            <td>{{ rate.name }}</td>
                            <td>{{ rate.value }}</td>
                            <td>{{ rate.seat?i18n.t('si'):i18n.t('no')}}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <h6>{{ $i18n.t("puntos_de_encuentro") }}:</h6>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ i18n.t('lugar') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('hora_de_encuentro') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('hora_de_salida') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(meeting_point, index) in previewed_items.event.meeting_points"
                            :key="index"
                          >
                            <td>{{ meeting_point.place }}</td>
                            <td>{{ meeting_point.time }}</td>
                            <td>{{ meeting_point.departure_time }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>       
                    
                    <h6>{{ $i18n.t("fotos") }}:</h6>
                    <v-container fluid v-if="!isEmpty(previewed_items.event.pictures)">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          class="d-flex child-flex"
                          v-for="(picture, index) in previewed_items.event.pictures"
                          v-bind:key="index"
                        >
                          <v-card class="mb-5">                            
                            <v-img
                              :src="getImageUrl(picture)"
                              max-height="220"
                              contain
                              class="grey darken-4"
                            ></v-img>                                                            
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>

                    <h6>{{ $i18n.t("videos") }}:</h6>
                    <v-container fluid v-if="!isEmpty(previewed_items.event.videos)">
                      <v-row>
                        <v-col
                          cols="12"
                          md="4"
                          v-for="(video, index) in previewed_items.event.videos"
                          v-bind:key="index"
                        >                                                           
                          
                          <v-card class="mb-5">
                            <div v-html="ytEmbedCodeFromUrl( video.url, 'width: 100%; min-height:190px' )"></div>
                            <a class="d-block ml-1 pa-1" target="_blank" :href="video.url">
                              <strong>{{ video.title }}</strong>
                            </a>                                               
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>   


                  </v-col>

                </v-row> 

              </v-container>
            </v-card-text>

            <v-card-actions style="position: sticky; bottom: 0">
              <v-spacer></v-spacer>
              <v-btn dark color="cancel-button darken-1" @click="closePreview()">
                {{ $t("cancelar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.events.delete" max-width="300px">
          <v-card>
            <v-card-title class="headline"
              >{{ $t("seguro_deseas_eliminar_registro") }}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{ $t("cancelar")}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItem"
                >{{ $t("eliminar") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item) in items"
            :key="item.id"
          >
            <td>
              <v-container fill-height fluid >
                <v-row class="py-1" align="center">

                  <v-col cols="5" sm="2" lg="1">
                      <img 
                        :src="getPrimaryPictureUrl(item)" 
                        class="pack-picture"
                        :style="'border-color:' + getStatusColor(item.status)"
                        @click="clicked(item)"
                        :title="item.id"
                      >                      
                  </v-col>

                  <v-col cols="7" sm="10" lg="6">

                    <h6>
                      <v-chip
                        :color="getStatusColor(item.status)"
                        dark
                        small
                        :title="item.status?i18n.t('estados.' + item.status):''"
                        >
                          {{ item.status?(i18n.t("estados." + item.status)).charAt(0):'' }}
                      </v-chip>
                      {{ item.name }}, {{ item.display_start_date }}<br/>
                    </h6>           
                    <i>{{ item.agency }}</i>

                  </v-col>

                  <v-col  cols="12" sm="12" lg="5">
                    <v-container>
                      <v-row class="my-1">

                        <v-col cols="12" md="6">
                          <div v-if="item.status == 'programado'">
                            <v-progress-linear
                              :color="getReservationBarColor(item)"
                              height="25"
                              :value="getReservationPercentage(item)"
                            >
                              <template v-slot:default>
                                <strong>{{ getReservationBarLabel( item ) }}</strong>
                              </template>
                            </v-progress-linear>
                          </div>                       
                        </v-col>                      
                      
                        <v-col cols="12" lg="6">
                          <v-icon class="mr-1 blue--text" @click="$router.push({ name: 'reservations', query: { event_id: item.id }})"> mdi-account-group </v-icon>               
                          <v-icon class="mr-1 view-button--text" @click="previewItemDialog(item)"> mdi-card-search</v-icon>
                          <v-icon class="mr-1 edit-button--text" @click="editItemDialog(item)"> mdi-file-document-edit</v-icon>
                          <v-icon class="delete-button--text" @click="deleteItemDialog(item)"> mdi-delete </v-icon>               
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>

                </v-row>
              </v-container>
            </td>
          </tr>
        </tbody>
      </template>

    </v-data-table>

    <v-pagination
      v-model="datatables.events.page"
      :length="datatables.events.page_count"
      v-if="datatables.events.total_items > 0 && datatables.events.page_count > 1"
    ></v-pagination>

  </div>
</template>

<style scoped src="@/views/Event/Events.css">
</style>

<script>
  import event from '@/views/Event/Events'
  export default event
</script>
