import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    'es': {
        bienvenido: 'Bienvenido',
        panel_de_control: 'Panel de Control',
        titulo_principal: 'Reservas fantasytours',
        idioma: 'Idioma',
        paquetes: 'Paquetes',
        chats: "Chats",
        clientes: 'Clientes',
        usuarios: 'Usuarios',
        cliente: "Cliente",
        reservas: "Reservas",
        eventos: "Eventos",
        perfil: 'Perfil',
        cerrar_sesion: 'Cerrar sesión',
        email: 'Email',
        ingrese_email_mensaje: 'Por favor ingrese su email',
        contraseña: 'Contraseña',
        iniciar_sesion: 'Iniciar sesión',
        nombre: 'Nombre',
        rol: 'Rol',
        estado: 'Estado',
        actividad: 'Actividad',
        activo: 'Activo',
        destinos: 'Destinos',
        recuerdame: 'Recuérdame',
        apellido: "Apellido",
        acciones: "Acciones",
        eliminar: "Eliminar",
        nuevo: "Nuevo",
        documento: "Documento",
        cancelar: "Cancelar",
        guardar: "Guardar",
        aplicar: "Aplicar",
        usuario: "Usuario",
        modificar_contraseña: "Modificar contraseña",
        error: "Error",
        autenticacion: {
            mensaje_error: "Usuario o contraseña incorrectos"
        },
        editar: "Editar",
        crear_nuevo: "Añadir",
        seguro_deseas_eliminar_registro: "¿Seguro que deseas eliminar este registro?",
        roles: {
            administrador: "Administrador",
            operador: "Operador",
            afiliado: "Afiliado",
            marketing: "Agente de Marketing",
            guia: "Guía"
        },
        estados: {
            inactivo: "Inactivo",
            activo: "Activo",
            eliminado: "Eliminado",
            finalizado: "Finalizado",
            anulado: "Anulado",
            enviado: "Enviado",
            espera: "En espera",
            programado: "Programado",
            cancelado: "Cancelado",
            borrador: "Borrador",
            todos: "Todos"
        },
        sexos: {
            masculino: "Masculino",
            femenino: "Femenino",
            otro: "Otro"
        },
        tipos_de_paquetes: {
            senderismo: "Senderismo",
            excursion: "Excursion",
            pasadia: "Pasadia/Día de Sol"
        },
        usuario_bloqueado: "El usuario está actualmente inactivo.",
        datos_incorrectos_inicio_sesion: "Usuario o contraseña incorrectos",
        validation_errors: "Errores de validación",
        informe_detalles_tecnicos_al_administrador: "Por favor informe estos detalles técnicos al administrador del sistema:",
        sin_datos: "No se han encontrado datos",
        fecha_de_nacimiento: "Fecha de nacimiento",
        telefono: "Teléfono",
        telefono_alternativo: "Teléfono Alternativo",
        sexo: "Sexo",
        informacion: "Información",
        programacion: "Programación",
        comprobante: "Comprobante",
        registrar_nuevo_pago: "Registrar nuevo pago",
        ingrese_valor_a_abonar: "Ingrese el valor que desea abonar",
        descargar_comprobante: "Descargar comprobante",
        ver_comprobante: "Ver comprobante",
        copiar_enlace_del_comprobante: "Copiar enlace del comprobante",
        plataforma: "Plataforma",
        bus_numero: "Numero de bus",
        numero_de_buses: "Numero de buses",
        grupo: "Grupo",
        imagen: "Imagen",
        tarifas: "Tarifas",
        opciones: "Opciones",
        fecha: "Fecha",
        inicio: "Inicio",
        fin: "Fin",
        varios: "Varios",
        descripcion_corta: "Descripción corta",
        escriba_descripcion_corta: "Escriba una descripción corta",
        tarifa: "Tarifa",
        nueva_tarifa: "Nueva tarifa",
        valor: "Valor",
        adulto: "Adulto",
        este_pasajero_tiene_grupo: "Este pasajero agrupa otros pasajeros",
        niño: "Niño",
        incluye: "Incluye",
        no_incluye: "No incluye",
        escriba_lo_que_incluye_el_evento: "Escriba lo que incluye el evento",
        escriba_lo_que_no_incluye_el_evento: "Escriba lo que NO incluye el evento",
        puntos_de_encuentro: "Puntos de encuentro",
        encuentro: "Encuentro",
        nuevo_punto_de_encuentro: "Nuevo punto de encuentro",
        lugar: "Lugar",
        lugar_de_encuentro: "Lugar de encuentro",
        pais: "País",
        ubicacion: "Ubicación",
        telefono_movil: "Número de celular",
        debe_especificar_el_pais: "Debe especificar el país",
        mostrar_cancelados: "Mostrar cancelados",
        mostrar_eliminados: "Mostrar eliminados",
        mostrar_finalizados: "Mostrar finalizados",
        cuanto_desea_abonar: "¿Cuánto desea abonar?",
        se_copio_el_enlace_de_descarga_del_voucher_siguiente: "Se copió el enlace del voucher siguiente",
        el_saldo_de_este_cliente_requiere_atencion: "El saldo de este cliente requiere atención",
        este_cliente_no_tiene_saldo_pendiente: "Este cliente no tiene saldo pendiente",
        el_valor_del_abono_debe_ser_diferente_de_0: "El valor del abono debe ser diferente de 0",
        pagos: "Pagos",
        saldo_pendiente: "Saldo pendiente",
        grupo_de: "Grupo de",
        hora_de_encuentro: "Hora de encuentro",
        hora: "Hora",
        hora_de_salida: "Hora de salida",
        cantidad_abonada_es_mayor_al_saldo_pendiente: "El valor abonado es superior al saldo pendiente",
        sin_puntos_de_encuentro_clic_crea_uno: "No hay puntos de encuentro creados. Haz clic en el botón \"Nuevo punto de encuentro\" para crear uno.",
        sin_tarifas_clic_crea_una: "No hay tarifas creadas. Haz clic en \"Nueva tarifa\" para crear una tarifa nueva.",
        seleccione_imagenes: "Seleccione imágenes",
        seleccione_imagenes_previsualizar_aqui: "Seleccione imágenes para previsualizarlas aquí",
        principal: "Principal",
        nuevo_video: "Nuevo video",
        sin_videos_clic_crea_uno: "No hay videos registrados. Clic en \"Nuevo video\" para registrar uno.",
        direccion_youtube: "Dirección URL de YouTube",
        titulo: "Título",
        recomendaciones: "Recomendaciones",
        observaciones: "Observaciones",
        escriba_observaciones: "Escriba las observaciones",
        escriba_recomendaciones: "Escriba las recomendaciones",
        tipo_de_paquete: "Tipo de paquete",
        distancia_total: "Distancia total",
        paquete: "Paquete",
        nivel_de_dificultad: "Nivel de dificultad",
        ocupa_silla: "Ocupa silla",
        silla: "Silla",
        ingrese_terminos_busqueda: "Ingrese los términos de búsqueda",
        tags: "Etiquetas",
        reservas_para: "Reservas para",
        años: "Año(s)",
        meses: "Mes(es)",
        creado: "Creado",
        contacto: "Contacto",
        puede_personalizar_este_valor: "Puede personalizar este valor",
        notas: "Notas",
        acomodacion: "Acomodación",
        tipos_de_acomodacion: {
            multiple: "Múltiple",
            sencilla: "Sencilla",
            doble: "Doble",
            no_aplica: "No aplica"
        },
        reserva: "Reserva",
        seleccione: "Seleccione",
        confirme_cambio_de_tarifa: "Por favor confirme el cambio de tarifa. La nueva tarifa será:",
        videos: "Vídeos",
        fotos: "Fotos",
        si: "Sí",
        no: "No",
        detalles_de: "Detalles de",
        ver_detalles: "Ver detalles",
        fecha_de_creacion: "Fecha de creación",
        cupo_minimo: "Cupo mínimo",
        cupo_maximo: "Cupo máximo",
        confirmado: "Confirmado",
        sin_confirmar: "Sin confirmar",
        de: "de",
        sin_resultados: "Sin resultados para {0}",
        ocurrio_un_error: "Ha ocurrido un error",
        el_tipo_de_objeto_es: "El tipo de objeto es",
        objeto_en_uso: "Se recomienda deshabilitar el registro en lugar de eliminarlo. Este registro ya está en uso (puede tener dependencias o estar asociado a otro objeto). Por ejemplo, si desea eliminar un cliente, este error quiere decir que este cliente ya está asociado a una reserva.",
        no_autorizado: "No está autorizado para realizar esta acción",
        no_se_puede_eliminar_cliente_en_asociado_a_reservas: "No se puede eliminar el cliente porque ya está asociado a una o más reservas. En su lugar, puedes desactivarlo.",
        hay_documentos_vacios_en_reservas_confirmadas: "Hay {0} documento(s) de identificación vacío(s) en reservas confirmadas. Corrija los errores antes de continuar.",
        hay_telefonos_vacios_en_reservas_confirmadas_de_lider_grupal: "Hay {0} número(s) de teléfono vacío(s) en líderes de grupo con reserva confirmada.",
        titular: "Titular",
        valor_total: "Valor total",
        valor_pagado: "Valor pagado",
        edad: "Edad",
        pasajeros: "Pasajeros",
        adicionar_usuario: "Añadir nuevo usuario",
        cancelar_reserva: "Cancelar reserva",
        lista_de_pagos: "Lista de pagos",
        hay_problemas_con_este_grupo: "Hay problemas que resolver en este grupo",
        saldo_incoherente: "El saldo es incoherente",
        desde: "Desde",
        hasta: "Hasta",
        saldo: "Saldo",
        comentario: "Comentario",
        fecha_de_vencimiento: "Vencimiento",
        incremento_tarifario: "Incremento tarifario",
        referencia: "Referencia",
        wc: {
            actualizar_imagenes_wc: "WooCommerce: Actualizar imágenes",
            mostrar_precio_regular: "WooCommerce: Mostrar precio regular"
        },
        formato_de_calendario: "Formato del calendario",
        formatos_de_calendario: {
            tsv: "Separado por tabuladores",
            csv: "Separado por comas"
        },
        agencia: "Agencia",
        agencia_operadora: "Agencia operadora",
        itinerario: "Itinerario",
        tipo_de_transporte: "Tipo de transporte",
        tipos_de_transporte: 
        {
            aereo: "Aéreo",
            terrestre: "Terrestre"
        },
        duracion_en_noches: "Duración en noches",
        articulo_web: "Artículo web",
        apodo: "Apodo",
        noches: "Noches",
        recurso: "Recurso",
        no_hay_conversacion_con_este_cliente: "No hay conversación con este cliente.",
        seleccione_un_cliente_para_cargar_conversacion: "Seleccione un cliente para cargar la conversación",
        seleccione_una_linea_para_cargar_conversacion: "Seleccione una línea para cargar las conversación"
    }
};

export default new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages,
})