import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import users from './modules/users'
import { mapGetters } from 'vuex'
import { Ability } from '@casl/ability'
import tokenSuscriber from '@/store/plugins/tokenSuscriber'
import abilitySuscriber from '@/store/plugins/abilitySuscriber'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
            isLoading: 'app/isLoading'
        })
    },
    plugins: [
        tokenSuscriber,
        abilitySuscriber
    ],
    mutations: {},
    actions: {},
    modules: {
        app,
        auth,
        users
    },
    getters: {
        ability() {
            return new Ability()
        }
    }
})