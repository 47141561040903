import axios from 'axios'
import i18n from '@/plugins/i18n'

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        abilities: null,
        roles: null,
        statuses: null,
        sexes: null,
        pack_types: null,
        accom_types: null,
        agencies: null
    },
    getters: {
        authenticated(state) {
            return state.user !== null
        },
        user(state) {
            return state.user
        },
        abilities(state) {
            return state.abilities
        },
        roles(state) {
            return state.roles
        },
        statuses(state) {
            return state.statuses
        },
        sexes(state) {
            return state.sexes
        },
        pack_types(state) {
            return state.pack_types
        },
        accom_types(state) {
            return state.accom_types
        },
        agencies(state) {
            return state.agencies
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },

        SET_USER(state, data) {
            state.user = data
        },

        SET_ABILITIES(state, abilities) {
            state.abilities = abilities
        },

        SET_ROLES(state, roles) {
            state.roles = roles
        },

        SET_STATUSES(state, statuses) {
            state.statuses = statuses
        },

        SET_SEXES(state, sexes) {
            state.sexes = sexes
        },

        SET_PACK_TYPES(state, pack_types) {
            state.pack_types = pack_types
        },

        SET_ACCOM_TYPES(state, accom_types) {
            state.accom_types = accom_types
        },
        SET_AGENCIES(state, agencies) {
            state.agencies = agencies
        },
    },
    actions: {
        async signIn({ dispatch }, credentials) {
            let response = await axios.post('users/signin', credentials)
            return dispatch('attempt', response.data.token)
        },
        async attempt({ commit, state }, token) {

            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return;
            }
            try {
                let response = await axios.get('users/abilities')
                commit('SET_USER', {
                        name: response.data.name,
                        email: response.data.email,
                    }),
                    commit('SET_ABILITIES', JSON.stringify(response.data.abilities))

                let translated_roles = []
                for (var i = 0; i < response.data.additional_data.roles.length; i++) {
                    translated_roles.push({
                        "value": response.data.additional_data.roles[i].role,
                        "text": i18n.t("roles." + String(response.data.additional_data.roles[i].role)),
                    })
                }
                commit('SET_ROLES', JSON.stringify(translated_roles))

                let translated_statuses = []
                for (i = 0; i < response.data.additional_data.statuses.length; i++) {
                    translated_statuses.push({
                        "value": response.data.additional_data.statuses[i],
                        "text": i18n.t("estados." + String(response.data.additional_data.statuses[i])),
                    })
                }
                commit('SET_STATUSES', JSON.stringify(translated_statuses))

                let translated_sexes = []
                for (i = 0; i < response.data.additional_data.sexes.length; i++) {
                    translated_sexes.push({
                        "value": response.data.additional_data.sexes[i],
                        "text": i18n.t("sexos." + String(response.data.additional_data.sexes[i])),
                    })
                }
                commit('SET_SEXES', JSON.stringify(translated_sexes))

                let translated_pack_types = []
                for (i = 0; i < response.data.additional_data.pack_types.length; i++) {
                    translated_pack_types.push({
                        "value": response.data.additional_data.pack_types[i],
                        "text": i18n.t("tipos_de_paquetes." + String(response.data.additional_data.pack_types[i])),
                    })
                }
                commit('SET_PACK_TYPES', JSON.stringify(translated_pack_types))

                let translated_accom_types = []
                for (i = 0; i < response.data.additional_data.accom_types.length; i++) {
                    translated_accom_types.push({
                        "value": response.data.additional_data.accom_types[i],
                        "text": i18n.t("tipos_de_acomodacion." + String(response.data.additional_data.accom_types[i])),
                    })
                }
                commit('SET_ACCOM_TYPES', JSON.stringify(translated_accom_types))

                let parsed_agencies = []
                for (i = 0; i < response.data.additional_data.agencies.length; i++) {
                    parsed_agencies.push({
                        "value": response.data.additional_data.agencies[i].id,
                        "text": String(response.data.additional_data.agencies[i].name)
                    })
                }
                commit('SET_AGENCIES', JSON.stringify(parsed_agencies))

                return response

            } catch (e) {

                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                commit('SET_ABILITIES', null)
                commit('SET_ROLES', null)
                commit('SET_STATUSES', null)
                commit('SET_SEXES', null)
                commit('SET_PACK_TYPES', null)
                commit('SET_ACCOM_TYPES', null)
                commit('SET_AGENCIES', null)

                return false

            }
        },
        signOut({ commit }) {
            return axios.post('users/signout').then(() => {
                commit("SET_TOKEN", null)
                commit("SET_USER", null)
                commit('SET_ABILITIES', null)
                commit('SET_ROLES', null)
                commit('SET_STATUSES', null)
                commit('SET_SEXES', null)
                commit('SET_PACK_TYPES', null)
                commit('SET_ACCOM_TYPES', null)
                commit('SET_AGENCIES', null)
            })
        }
    }
}