<template>

  <div class="overflow-hidden" v-if="authenticated">
    <v-bottom-navigation
      v-model="value"
      :input-value="active"
      color="green darken-4"
      fixed
    >
      <v-btn v-if="$can('create','User')">
        <router-link
            :to="{
              name: 'users',
            }"
            >
          <v-icon>mdi-account-key</v-icon><br>
          <span>{{ $t("usuarios") }}</span>
        </router-link>
      </v-btn>

      <v-btn>
        <router-link
            :to="{
              name: 'notes',
            }"
            >
          <v-icon>mdi-file-edit</v-icon><br>
          <span>{{ $t("notas") }}</span>
        </router-link>
      </v-btn>

      <v-btn>
        <router-link
            :to="{
              name: 'conversations',
            }"
            >
          <v-icon>mdi-chat</v-icon><br>
          <span>{{ $t("chats") }}</span>
        </router-link>
      </v-btn>


      <v-btn>
        <router-link
            :to="{
              name: 'clients',
            }"
            >
            <v-icon>mdi-account-group</v-icon><br>
            <span>{{ $t("clientes") }}</span>
          </router-link>
      </v-btn>

      <v-btn>        
          <router-link
            :to="{
              name: 'packs',
            }"
            >
            <v-icon>mdi-map-marker-radius</v-icon><br>
            <span>{{ $t("paquetes") }}</span>
          </router-link>
      </v-btn>

      <v-btn>        
          <router-link
            :to="{
              name: 'events',
            }"
            >
            <v-icon>mdi-calendar</v-icon><br>
            <span>{{ $t("programacion") }}</span>
          </router-link>
      </v-btn>

    </v-bottom-navigation>
    <br />
    <br />
  </div>
</template> 

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      value: 1,
      active: true,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      abilities: "auth/abilities",
    }),
  },
};
</script>