import colorHelper from '@/libs/helpers/colorHelper'
import validationHelper from '@/libs/helpers/validationHelper'
import globalDataHelper from '@/libs/helpers/globalDataHelper'

export default {
    mixins: [
        colorHelper,
        validationHelper,
        globalDataHelper
    ],
    name: "users",
    components: {},
    data: () => ({
        dialogs: {
            user: {
                edit: false,
                delete: false,
            }
        },
        users: [],
        edited_items: {
            user: {
                edit_password: false
            }
        },
        default_items: {
            user: {
                edit_password: true,
                status: "activo",
                role: "afiliado"
            }
        }
    }),

    computed: {

        formTitle() {
            return (this.datatables.users.edited_index === -1 ? this.$i18n.t("crear_nuevo") : this.$i18n.t("editar")) + " " + this.$i18n.t("usuario");
        },

        statuses() {
            return JSON.parse(localStorage.getItem("statuses")).filter(function(item) {
                return ["activo", "inactivo"].includes(item.value)
            })
        }

    },

    watch: {
        "dialogs.user.edit": function(val) {
            val || this.closeEdit();
        },
        "dialogs.user.delete": function(val) {
            val || this.closeDelete();
        },
        "datatables.users.options": {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {

            this.datatables.users.headers = [{
                    text: this.i18n.t("nombre"),
                    align: "start",
                    value: "name",
                },
                { text: this.i18n.t("email"), value: "email" },
                { text: this.i18n.t("documento"), value: "document" },
                { text: this.i18n.t("rol"), value: "role" },
                { text: this.i18n.t("estado"), value: "status" },
                { text: this.i18n.t("acciones"), value: "actions", sortable: false },
            ]

            this.setTitle(this.i18n.t("usuarios"))

        },

        getDataFromApi() {

            this.setLoading(true)

            this.$axios("users").then(response => {
                    this.users = response.data.items
                    this.total_items = response.data.total
                })
                .finally(() => {
                    this.setLoading(false)
                })
        },

        editItemDialog(item) {
            this.datatables.users.edited_index = this.users.indexOf(item);

            if (this.datatables.users.edited_index == -1) {
                this.edited_items.user = Object.assign({}, this.default_items.user);
            } else {
                this.edited_items.user = Object.assign({}, item);
            }

            this.dialogs.user.edit = true;

        },

        deleteItemDialog(item) {
            this.datatables.users.edited_index = this.users.indexOf(item);
            this.edited_items.user = Object.assign({}, item);
            this.dialogs.user.delete = true;
        },

        deleteItem() {

            let _edited_index = this.datatables.users.edited_index

            this.setLoading(true)

            this.$axios({
                    url: "users",
                    method: "DELETE",
                    params: {
                        id: this.edited_items.user.id
                    }
                })
                .then(() => {
                    this.users.splice(_edited_index, 1);
                    this.closeDelete();
                })
                .catch(e => {
                    this.validation_errors = this.getValidationErrorsHtml(e)
                })
                .finally(() => {
                    this.setLoading(false)
                })

        },

        closeEdit() {
            this.dialogs.user.edit = false;
            this.$nextTick(() => {
                this.edited_items.user = Object.assign({}, this.default_items.user);
                this.datatables.users.edited_index = -1;
            });
        },

        closeDelete() {
            this.dialogs.user.delete = false;
            this.$nextTick(() => {
                this.edited_items.user = Object.assign({}, this.default_items.user);
                this.datatables.users.edited_index = -1;
            });
        },

        saveItem() {

            this.setLoading(true)

            if (this.datatables.users.edited_index > -1) {

                let _edited_index = this.datatables.users.edited_index

                this.$axios({
                        url: "users",
                        method: "PATCH",
                        params: {
                            id: this.edited_items.user.id
                        },
                        data: this.edited_items.user
                    })
                    .then(() => {


                        Object.assign(this.users[_edited_index], this.edited_items.user)
                        this.closeEdit();

                        this.validation_errors = null
                    })
                    .catch(e => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                    })
                    .finally(() => {
                        this.setLoading(false)
                    })

            } else {

                this.$axios({
                        url: "users",
                        method: "PUT",
                        data: this.edited_items.user
                    })
                    .then(() => {


                        this.closeEdit();
                        this.getDataFromApi()

                        this.validation_errors = null

                    })
                    .catch(e => {

                        this.validation_errors = this.getValidationErrorsHtml(e)

                    })
                    .finally(() => {
                        this.setLoading(false)
                    })

            }
        }

    },
};