/**
 * 
 * @param {date} date Date to be formatted
 * @param {string} format Format to be applied, default: long
 * @param {string} locale Locale string, default: es-CO
 * @param {string} zone Time zone string, default: America/Bogota
 */
 function toDateStrig(date, format = 'long', locale = 'es-CO') {

   let options = {
      long: { year: 'numeric', month: 'long', weekday: 'long', day: 'numeric', timeZone: 'UTC' }
   }

   return new Date(date).toLocaleString(locale, options[format])

}

export default {
   toDateStrig
}