import numberHelper from "@/libs/helpers/numberHelper.js";
import validationHelper from "@/libs/helpers/validationHelper.js";
import globalDataHelper from "@/libs/helpers/globalDataHelper.js";

export default {
  props: {
    sale: Object,
  },
  components: {},
  mixins: [numberHelper, validationHelper, globalDataHelper],
  created() {},
  data: () => ({
    payment: [],
    default_payment: {
      id: 0,
      value: 0,
      notes: null,
      voucher: null,
      payment_medium: "transferencia",
      platform: 'panel'
    },
  }),

  mounted(){
    this.resetForm()
  },
  
  methods: {

    resetForm(){
      this.payment = Object.assign({}, this.default_payment)
    },    
    pendingBalance() {
      return this.sale.total_amount - this.sale.paid_amount;
    },
    savePayment() {
      if (
        this.payment.value >
        this.pendingBalance()
      ) {
        this.showAlert(
          this.i18n.t("cantidad_abonada_es_mayor_al_saldo_pendiente")
        );
        return;
      }

      if (this.payment.value === 0) {
        this.showAlert(
          this.i18n.t("el_valor_del_abono_debe_ser_diferente_de_0")
        );
        return;
      }

      var _edited_payment = Object.assign({}, this.payment);

      this.setLoading(true);

      _edited_payment.sale_id = this.sale.id;

      this.validation_errors = null;
      
      this.$axios({
        url: "payments",
        method: "PUT",
        data: _edited_payment,
      })
        .then(() => {
          this.validation_errors = null;
        })
        .catch((e) => {
          this.validation_errors = this.getValidationErrorsHtml(e);
        })
        .finally(() => {
          this.setLoading(false);
          this.resetForm();
          this.$emit('payment-added');
        });
      
    }    
  },
};
