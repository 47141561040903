<template>
	<div>
		<v-container>
			<div class="pa-3 pl-5">
				<v-row class="mb-0">
					<v-col cols="12">
						<v-combobox
							v-model="current_prospect"
							item-text="phone"
							item-value="phone"
							:items="prospects"
							:label="$t('cliente')"
							outlined
							:search-input.sync="datatables.prospects.search"
							no-filter
							hide-details
							dense
						>
							<template v-slot:no-data>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>
											🔎
											{{
												$t('sin_resultados', [
													datatables.prospects.search,
												])
											}}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</template>
						</v-combobox>
					</v-col>
				
				</v-row>
				<v-row v-if="available_origins.length && current_prospect.phone">
					<v-col cols="12">
						<v-radio-group v-model="current_origin" row>
							<v-radio
								v-for="origin in available_origins"
								:key="origin.origin"
								:label="origin.origin"
								:value="origin.origin"
								:disabled="
									!messages.filter((m) => m.origin == origin.origin)
										.length
								"
							></v-radio>
						</v-radio-group>
					</v-col>
				</v-row>

				<v-row v-if="!messages.length && current_prospect.phone">
					<v-col
						cols="12"
					>
						<v-icon color="orange" medium>
							mdi-alert
						</v-icon>
						{{ $t('no_hay_conversacion_con_este_cliente') }}
					</v-col>

				</v-row>
				<v-row v-if="!current_prospect.phone || isEmpty(current_prospect)">

					<v-col cols="12" >
						<v-icon color="orange" medium>
							mdi-alert
						</v-icon>
						{{ $t('seleccione_un_cliente_para_cargar_conversacion') }}
					</v-col>
				
				</v-row>
				<v-row v-if="!current_origin && available_origins.length">

					<v-col cols="12">
						<v-icon color="orange" medium>
							mdi-alert
						</v-icon>
						{{ $t('seleccione_una_linea_para_cargar_conversacion') }}
					</v-col>
				
				</v-row>
				<v-row>

					<v-col cols="12">
						<ul id="conversation">
							<li
								class="message"
								v-for="message in messages.filter((m) => {
									return m.origin == this.current_origin
								})"
								v-bind:key="message.id"
							>
								<span v-html="message.message"></span>

								<span class="message-date">{{
									formatDate(message.date)
								}}</span>

								<ol
									v-if="message.prospect_images.length"
									class="message-media"
								>
									<li
										v-for="image in message.prospect_images"
										v-bind:key="image.key"
									>
										<a
											target="_image"
											:href="getConversationImageUrl(message, image)"
											>{{ $t('recurso') }}</a
										>
									</li>
								</ol>
							</li>
						</ul>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<style src="@/views/Prospect/ProspectMessages.css"></style>

<script>
	import conversations from '@/views/Prospect/ProspectMessages'
	export default conversations
</script>
