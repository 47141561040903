var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authenticated)?_c('div',{staticClass:"overflow-hidden"},[_c('v-bottom-navigation',{attrs:{"input-value":_vm.active,"color":"green darken-4","fixed":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[(_vm.$can('create','User'))?_c('v-btn',[_c('router-link',{attrs:{"to":{
            name: 'users',
          }}},[_c('v-icon',[_vm._v("mdi-account-key")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("usuarios")))])],1)],1):_vm._e(),_c('v-btn',[_c('router-link',{attrs:{"to":{
            name: 'notes',
          }}},[_c('v-icon',[_vm._v("mdi-file-edit")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("notas")))])],1)],1),_c('v-btn',[_c('router-link',{attrs:{"to":{
            name: 'conversations',
          }}},[_c('v-icon',[_vm._v("mdi-chat")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("chats")))])],1)],1),_c('v-btn',[_c('router-link',{attrs:{"to":{
            name: 'clients',
          }}},[_c('v-icon',[_vm._v("mdi-account-group")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("clientes")))])],1)],1),_c('v-btn',[_c('router-link',{attrs:{"to":{
            name: 'packs',
          }}},[_c('v-icon',[_vm._v("mdi-map-marker-radius")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("paquetes")))])],1)],1),_c('v-btn',[_c('router-link',{attrs:{"to":{
            name: 'events',
          }}},[_c('v-icon',[_vm._v("mdi-calendar")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.$t("programacion")))])],1)],1)],1),_c('br'),_c('br')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }