<template>
  <div>
    <v-container class="text-center">

        <span>{{ $t("sin_datos")}}</span>

    </v-container>
  </div>
</template>

<script>
    export default {
      methods: {  }
    };
</script>