export default {
    methods: {
        showAlert(alertObject, alertTitle = "error", alertType = "error"){
            switch (typeof alertObject){
                case 'object':
                    this.$swal.fire( this.$t(alertTitle), alertObject.response.data.message || alertObject.message, alertType )
                    break
                case 'string':
                    this.$swal.fire( this.$t(alertTitle), alertObject, alertType)
                    break
            }
        },
        getValidationErrorsHtml(e){
            console.log(e)
            if(e.response !== undefined && e.response.data !== undefined){
                if( e.response.data.errors !== undefined ){
                    if( Object.keys(e.response.data.errors).length ){
                        let errHtml = "<p class='validation-error-list-title text-left'><strong>" + this.$t("validation_errors") + "</strong></p>";
                        errHtml += "<ul class='validation-error-list  text-left'>";
                        for (const error in e.response.data.errors){
                            errHtml += "<li>" + e.response.data.errors[error][0] + "</li>"
                        }
                        errHtml += "</ul>"
                        return errHtml;
                    }
                    return null            
                }else if(e.response.data.code && e.response.system_message == undefined ){
                    let errHtml = '<p class="text-left"><strong>' + this.$t("ocurrio_un_error") + '</strong></p>'
                    errHtml += '<p class="text-left left">' + this.$t(e.response.data.message) + '</p>'
                    if(e.response.data.object_type !== undefined){
                        errHtml += this.$t("el_tipo_de_objeto_es") + " '" + e.response.data.object_type + "'"
                    }
                    return errHtml
                }else if(e.response.data.system_message == true ){
                    let errHtml = '<p class="text-left"><strong>' + this.$t("informe_detalles_tecnicos_al_administrador") + '</strong></p>'
                    errHtml += '<p class="text-left left">' + e.response.data.message + '</p>'
                    return errHtml
                }
            }
            else if(e.message !== undefined){
                let errHtml = '<p class="text-left"><strong>' + this.$t("informe_detalles_tecnicos_al_administrador") + '</strong></p>'
                    errHtml += '<p class="text-left left">' + e.message + '</p>'
                    if(typeof e.stack !== undefined) {
                        errHtml += '<p class="text-left left">' + e.stack + '</p>'
                    }
                return errHtml
            }

            // Reset validation errors
            this.validationErrors = null
        },
        isEmpty( val ){
            return !!(
                val === null 
                || val === "" 
                || val === "0" 
                || val === -1 
                || val === 0 
                || val === "-1" 
                || val === "0" 
                || val === false 
                || val === "false" 
                || val === "FALSE" 
                || val === "null" 
                || val === "undefined" 
                || val === undefined
                || (typeof val === 'string' && String(val).trim() === "")
                || (typeof val === 'object' && Object.keys(val).length === 0)
                || (typeof val === 'string' && String(val).replace(/ /g,'').length == 0)
                || (typeof val === 'string' && String(val).replace(/\s/g,'').length == 0)
                || (typeof val === 'string' && String(val).replace(/\s+/g, '').length == 0)
            )
        }
    },
    watch: {
        validation_errors: function(val){
            console.log( val )
            !val || this.showAlert(val)
        }
    },
    data() {
        return {
            validation_errors: null
        }
    }
}