import ClientProfile from "@/components/Client/Profile"

import colorHelper from '@/libs/helpers/colorHelper'
import validationHelper from '@/libs/helpers/validationHelper'
import globalDataHelper from '@/libs/helpers/globalDataHelper'

export default {
    mixins: [
        colorHelper,
        validationHelper,
        globalDataHelper
    ],
    name: "clients",
    components: {
        ClientProfile
    },
    data: () => ({
        datatables: {
            clients: {
                options: {
                    itemsPerPage: 50
                }
            }
        },
        dialogs: {
            client: {
                edit: false,
                delete: false,
                client_birthday: false,
                profile: false
            }
        },
        clients: [],
        edited_item: {
            client: {
                edit_password: null
            }
        },
        default_items: {
            client: {
                edit_password: null,
                status: "activo",
                tags: [],
                country: {
                    "country": "Colombia ",
                    "code": "CO",
                    "dialing_code": "57"
                }
            }
        },
        search: '',
        all_tags: [],
        all_countries: [{
                "country": "Afghanistan ",
                "code": "AF",
                "dialing_code": "93"
            },
            {
                "country": "Albania ",
                "code": "AL",
                "dialing_code": "355"
            },
            {
                "country": "Algeria ",
                "code": "DZ",
                "dialing_code": "213"
            },
            {
                "country": "American Samoa",
                "code": "AS",
                "dialing_code": "1-684"
            },
            {
                "country": "Andorra, Principality of ",
                "code": "AD",
                "dialing_code": "376"
            },
            {
                "country": "Angola",
                "code": "AO",
                "dialing_code": "244"
            },
            {
                "country": "Anguilla ",
                "code": "AI",
                "dialing_code": "1-264"
            },
            {
                "country": "Antarctica",
                "code": "AQ",
                "dialing_code": "672"
            },
            {
                "country": "Antigua and Barbuda",
                "code": "AG",
                "dialing_code": "1-268"
            },
            {
                "country": "Argentina ",
                "code": "AR",
                "dialing_code": "54"
            },
            {
                "country": "Armenia",
                "code": "AM",
                "dialing_code": "374"
            },
            {
                "country": "Aruba",
                "code": "AW",
                "dialing_code": "297"
            },
            {
                "country": "Australia",
                "code": "AU",
                "dialing_code": "61"
            },
            {
                "country": "Austria",
                "code": "AT",
                "dialing_code": "43"
            },
            {
                "country": "Azerbaijan or Azerbaidjan (Former Azerbaijan Soviet Socialist Republic)",
                "code": "AZ",
                "dialing_code": "994"
            },
            {
                "country": "Bahamas, Commonwealth of The",
                "code": "BS",
                "dialing_code": "1-242"
            },
            {
                "country": "Bahrain, Kingdom of (Former Dilmun)",
                "code": "BH",
                "dialing_code": "973"
            },
            {
                "country": "Bangladesh (Former East Pakistan)",
                "code": "BD",
                "dialing_code": "880"
            },
            {
                "country": "Barbados ",
                "code": "BB",
                "dialing_code": "1-246"
            },
            {
                "country": "Belarus (Former Belorussian [Byelorussian] Soviet Socialist Republic)",
                "code": "BY",
                "dialing_code": "375"
            },
            {
                "country": "Belgium ",
                "code": "BE",
                "dialing_code": "32"
            },
            {
                "country": "Belize (Former British Honduras)",
                "code": "BZ",
                "dialing_code": "501"
            },
            {
                "country": "Benin (Former Dahomey)",
                "code": "BJ",
                "dialing_code": "229"
            },
            {
                "country": "Bermuda ",
                "code": "BM",
                "dialing_code": "1-441"
            },
            {
                "country": "Bhutan, Kingdom of",
                "code": "BT",
                "dialing_code": "975"
            },
            {
                "country": "Bolivia ",
                "code": "BO",
                "dialing_code": "591"
            },
            {
                "country": "Bosnia and Herzegovina ",
                "code": "BA",
                "dialing_code": "387"
            },
            {
                "country": "Botswana (Former Bechuanaland)",
                "code": "BW",
                "dialing_code": "267"
            },
            {
                "country": "Bouvet Island (Territory of Norway)",
                "code": "BV"
            },
            {
                "country": "Brazil ",
                "code": "BR",
                "dialing_code": "55"
            },
            {
                "country": "British Indian Ocean Territory (BIOT)",
                "code": "IO"
            },
            {
                "country": "Brunei (Negara Brunei Darussalam) ",
                "code": "BN",
                "dialing_code": "673"
            },
            {
                "country": "Bulgaria ",
                "code": "BG",
                "dialing_code": "359"
            },
            {
                "country": "Burkina Faso (Former Upper Volta)",
                "code": "BF",
                "dialing_code": "226"
            },
            {
                "country": "Burundi (Former Urundi)",
                "code": "BI",
                "dialing_code": "257"
            },
            {
                "country": "Cambodia, Kingdom of (Former Khmer Republic, Kampuchea Republic)",
                "code": "KH",
                "dialing_code": "855"
            },
            {
                "country": "Cameroon (Former French Cameroon)",
                "code": "CM",
                "dialing_code": "237"
            },
            {
                "country": "Canada ",
                "code": "CA",
                "dialing_code": "1"
            },
            {
                "country": "Cape Verde ",
                "code": "CV",
                "dialing_code": "238"
            },
            {
                "country": "Cayman Islands ",
                "code": "KY",
                "dialing_code": "1-345"
            },
            {
                "country": "Central African Republic ",
                "code": "CF",
                "dialing_code": "236"
            },
            {
                "country": "Chad ",
                "code": "TD",
                "dialing_code": "235"
            },
            {
                "country": "Chile ",
                "code": "CL",
                "dialing_code": "56"
            },
            {
                "country": "China ",
                "code": "CN",
                "dialing_code": "86"
            },
            {
                "country": "Christmas Island ",
                "code": "CX",
                "dialing_code": "53"
            },
            {
                "country": "Cocos (Keeling) Islands ",
                "code": "CC",
                "dialing_code": "61"
            },
            {
                "country": "Colombia ",
                "code": "CO",
                "dialing_code": "57"
            },
            {
                "country": "Comoros, Union of the ",
                "code": "KM",
                "dialing_code": "269"
            },
            {
                "country": "Congo, Democratic Republic of the (Former Zaire) ",
                "code": "CD",
                "dialing_code": "243"
            },
            {
                "country": "Congo, Republic of the",
                "code": "CG",
                "dialing_code": "242"
            },
            {
                "country": "Cook Islands (Former Harvey Islands)",
                "code": "CK",
                "dialing_code": "682"
            },
            {
                "country": "Costa Rica ",
                "code": "CR",
                "dialing_code": "506"
            },
            {
                "country": "Cote D'Ivoire (Former Ivory Coast) ",
                "code": "CI",
                "dialing_code": "225"
            },
            {
                "country": "Croatia (Hrvatska) ",
                "code": "HR",
                "dialing_code": "385"
            },
            {
                "country": "Cuba ",
                "code": "CU",
                "dialing_code": "53"
            },
            {
                "country": "Cyprus ",
                "code": "CY",
                "dialing_code": "357"
            },
            {
                "country": "Czech Republic",
                "code": "CZ",
                "dialing_code": "420"
            },
            {
                "country": "Czechoslavakia (Former) See CZ Czech Republic or Slovakia",
                "code": "CS"
            },
            {
                "country": "Denmark ",
                "code": "DK",
                "dialing_code": "45"
            },
            {
                "country": "Djibouti (Former French Territory of the Afars and Issas, French Somaliland)",
                "code": "DJ",
                "dialing_code": "253"
            },
            {
                "country": "Dominica ",
                "code": "DM",
                "dialing_code": "1-767"
            },
            {
                "country": "Dominican Republic ",
                "code": "DO",
                "dialing_code": "+1-809 and +1-829  "
            },
            {
                "country": "East Timor (Former Portuguese Timor)",
                "code": "TP",
                "dialing_code": "670"
            },
            {
                "country": "Ecuador ",
                "code": "EC",
                "dialing_code": "593"
            },
            {
                "country": "Egypt (Former United Arab Republic - with Syria)",
                "code": "EG",
                "dialing_code": "20"
            },
            {
                "country": "El Salvador ",
                "code": "SV",
                "dialing_code": "503"
            },
            {
                "country": "Equatorial Guinea (Former Spanish Guinea)",
                "code": "GQ",
                "dialing_code": "240"
            },
            {
                "country": "Eritrea (Former Eritrea Autonomous Region in Ethiopia)",
                "code": "ER",
                "dialing_code": "291"
            },
            {
                "country": "Estonia (Former Estonian Soviet Socialist Republic)",
                "code": "EE",
                "dialing_code": "372"
            },
            {
                "country": "Ethiopia (Former Abyssinia, Italian East Africa)",
                "code": "ET",
                "dialing_code": "251"
            },
            {
                "country": "Falkland Islands (Islas Malvinas) ",
                "code": "FK",
                "dialing_code": "500"
            },
            {
                "country": "Faroe Islands ",
                "code": "FO",
                "dialing_code": "298"
            },
            {
                "country": "Fiji ",
                "code": "FJ",
                "dialing_code": "679"
            },
            {
                "country": "Finland ",
                "code": "FI",
                "dialing_code": "358"
            },
            {
                "country": "France ",
                "code": "FR",
                "dialing_code": "33"
            },
            {
                "country": "French Guiana or French Guyana ",
                "code": "GF",
                "dialing_code": "594"
            },
            {
                "country": "French Polynesia (Former French Colony of Oceania)",
                "code": "PF",
                "dialing_code": "689"
            },
            {
                "country": "French Southern Territories and Antarctic Lands ",
                "code": "TF"
            },
            {
                "country": "Gabon (Gabonese Republic)",
                "code": "GA",
                "dialing_code": "241"
            },
            {
                "country": "Gambia, The ",
                "code": "GM",
                "dialing_code": "220"
            },
            {
                "country": "Georgia (Former Georgian Soviet Socialist Republic)",
                "code": "GE",
                "dialing_code": "995"
            },
            {
                "country": "Germany ",
                "code": "DE",
                "dialing_code": "49"
            },
            {
                "country": "Ghana (Former Gold Coast)",
                "code": "GH",
                "dialing_code": "233"
            },
            {
                "country": "Gibraltar ",
                "code": "GI",
                "dialing_code": "350"
            },
            {
                "country": "Great Britain (United Kingdom) ",
                "code": "GB",
                "dialing_code": "44"
            },
            {
                "country": "Greece ",
                "code": "GR",
                "dialing_code": "30"
            },
            {
                "country": "Greenland ",
                "code": "GL",
                "dialing_code": "299"
            },
            {
                "country": "Grenada ",
                "code": "GD",
                "dialing_code": "1-473"
            },
            {
                "country": "Guadeloupe",
                "code": "GP",
                "dialing_code": "590"
            },
            {
                "country": "Guam",
                "code": "GU",
                "dialing_code": "1-671"
            },
            {
                "country": "Guatemala ",
                "code": "GT",
                "dialing_code": "502"
            },
            {
                "country": "Guinea (Former French Guinea)",
                "code": "GN",
                "dialing_code": "224"
            },
            {
                "country": "Guinea-Bissau (Former Portuguese Guinea)",
                "code": "GW",
                "dialing_code": "245"
            },
            {
                "country": "Guyana (Former British Guiana)",
                "code": "GY",
                "dialing_code": "592"
            },
            {
                "country": "Haiti ",
                "code": "HT",
                "dialing_code": "509"
            },
            {
                "country": "Honduras ",
                "code": "HN",
                "dialing_code": "504"
            },
            {
                "country": "Hong Kong ",
                "code": "HK",
                "dialing_code": "852"
            },
            {
                "country": "Hungary ",
                "code": "HU",
                "dialing_code": "36"
            },
            {
                "country": "Iceland ",
                "code": "IS",
                "dialing_code": "354"
            },
            {
                "country": "India ",
                "code": "IN",
                "dialing_code": "91"
            },
            {
                "country": "Indonesia (Former Netherlands East Indies; Dutch East Indies)",
                "code": "ID",
                "dialing_code": "62"
            },
            {
                "country": "Iran, Islamic Republic of",
                "code": "IR",
                "dialing_code": "98"
            },
            {
                "country": "Iraq ",
                "code": "IQ",
                "dialing_code": "964"
            },
            {
                "country": "Ireland ",
                "code": "IE",
                "dialing_code": "353"
            },
            {
                "country": "Israel ",
                "code": "IL",
                "dialing_code": "972"
            },
            {
                "country": "Italy ",
                "code": "IT",
                "dialing_code": "39"
            },
            {
                "country": "Jamaica ",
                "code": "JM",
                "dialing_code": "1-876"
            },
            {
                "country": "Japan ",
                "code": "JP",
                "dialing_code": "81"
            },
            {
                "country": "Jordan (Former Transjordan)",
                "code": "JO",
                "dialing_code": "962"
            },
            {
                "country": "Kazakstan or Kazakhstan (Former Kazakh Soviet Socialist Republic)",
                "code": "KZ",
                "dialing_code": "7"
            },
            {
                "country": "Kenya (Former British East Africa)",
                "code": "KE",
                "dialing_code": "254"
            },
            {
                "country": "Kiribati (Pronounced keer-ree-bahss) (Former Gilbert Islands)",
                "code": "KI",
                "dialing_code": "686"
            },
            {
                "country": "Korea, Democratic People's Republic of (North Korea)",
                "code": "KP",
                "dialing_code": "850"
            },
            {
                "country": "Korea, Republic of (South Korea) ",
                "code": "KR",
                "dialing_code": "82"
            },
            {
                "country": "Kuwait ",
                "code": "KW",
                "dialing_code": "965"
            },
            {
                "country": "Kyrgyzstan (Kyrgyz Republic) (Former Kirghiz Soviet Socialist Republic)",
                "code": "KG",
                "dialing_code": "996"
            },
            {
                "country": "Lao People's Democratic Republic (Laos)",
                "code": "LA",
                "dialing_code": "856"
            },
            {
                "country": "Latvia (Former Latvian Soviet Socialist Republic)",
                "code": "LV",
                "dialing_code": "371"
            },
            {
                "country": "Lebanon ",
                "code": "LB",
                "dialing_code": "961"
            },
            {
                "country": "Lesotho (Former Basutoland)",
                "code": "LS",
                "dialing_code": "266"
            },
            {
                "country": "Liberia ",
                "code": "LR",
                "dialing_code": "231"
            },
            {
                "country": "Libya (Libyan Arab Jamahiriya)",
                "code": "LY",
                "dialing_code": "218"
            },
            {
                "country": "Liechtenstein ",
                "code": "LI",
                "dialing_code": "423"
            },
            {
                "country": "Lithuania (Former Lithuanian Soviet Socialist Republic)",
                "code": "LT",
                "dialing_code": "370"
            },
            {
                "country": "Luxembourg ",
                "code": "LU",
                "dialing_code": "352"
            },
            {
                "country": "Macau ",
                "code": "MO",
                "dialing_code": "853"
            },
            {
                "country": "Macedonia, The Former Yugoslav Republic of",
                "code": "MK",
                "dialing_code": "389"
            },
            {
                "country": "Madagascar (Former Malagasy Republic)",
                "code": "MG",
                "dialing_code": "261"
            },
            {
                "country": "Malawi (Former British Central African Protectorate, Nyasaland)",
                "code": "MW",
                "dialing_code": "265"
            },
            {
                "country": "Malaysia ",
                "code": "MY",
                "dialing_code": "60"
            },
            {
                "country": "Maldives ",
                "code": "MV",
                "dialing_code": "960"
            },
            {
                "country": "Mali (Former French Sudan and Sudanese Republic) ",
                "code": "ML",
                "dialing_code": "223"
            },
            {
                "country": "Malta ",
                "code": "MT",
                "dialing_code": "356"
            },
            {
                "country": "Marshall Islands (Former Marshall Islands District - Trust Territory of the Pacific Islands)",
                "code": "MH",
                "dialing_code": "692"
            },
            {
                "country": "Martinique (French) ",
                "code": "MQ",
                "dialing_code": "596"
            },
            {
                "country": "Mauritania ",
                "code": "MR",
                "dialing_code": "222"
            },
            {
                "country": "Mauritius ",
                "code": "MU",
                "dialing_code": "230"
            },
            {
                "country": "Mayotte (Territorial Collectivity of Mayotte)",
                "code": "YT",
                "dialing_code": "269"
            },
            {
                "country": "Mexico ",
                "code": "MX",
                "dialing_code": "52"
            },
            {
                "country": "Micronesia, Federated States of (Former Ponape, Truk, and Yap Districts - Trust Territory of the Pacific Islands)",
                "code": "FM",
                "dialing_code": "691"
            },
            {
                "country": "Moldova, Republic of",
                "code": "MD",
                "dialing_code": "373"
            },
            {
                "country": "Monaco, Principality of",
                "code": "MC",
                "dialing_code": "377"
            },
            {
                "country": "Mongolia (Former Outer Mongolia)",
                "code": "MN",
                "dialing_code": "976"
            },
            {
                "country": "Montserrat ",
                "code": "MS",
                "dialing_code": "1-664"
            },
            {
                "country": "Morocco ",
                "code": "MA",
                "dialing_code": "212"
            },
            {
                "country": "Mozambique (Former Portuguese East Africa)",
                "code": "MZ",
                "dialing_code": "258"
            },
            {
                "country": "Myanmar, Union of (Former Burma)",
                "code": "MM",
                "dialing_code": "95"
            },
            {
                "country": "Namibia (Former German Southwest Africa, South-West Africa)",
                "code": "NA",
                "dialing_code": "264"
            },
            {
                "country": "Nauru (Former Pleasant Island)",
                "code": "NR",
                "dialing_code": "674"
            },
            {
                "country": "Nepal ",
                "code": "NP",
                "dialing_code": "977"
            },
            {
                "country": "Netherlands ",
                "code": "NL",
                "dialing_code": "31"
            },
            {
                "country": "Netherlands Antilles (Former Curacao and Dependencies)",
                "code": "AN",
                "dialing_code": "599"
            },
            {
                "country": "New Caledonia ",
                "code": "NC",
                "dialing_code": "687"
            },
            {
                "country": "New Zealand (Aotearoa) ",
                "code": "NZ",
                "dialing_code": "64"
            },
            {
                "country": "Nicaragua ",
                "code": "NI",
                "dialing_code": "505"
            },
            {
                "country": "Niger ",
                "code": "NE",
                "dialing_code": "227"
            },
            {
                "country": "Nigeria ",
                "code": "NG",
                "dialing_code": "234"
            },
            {
                "country": "Niue (Former Savage Island)",
                "code": "NU",
                "dialing_code": "683"
            },
            {
                "country": "Norfolk Island ",
                "code": "NF",
                "dialing_code": "672"
            },
            {
                "country": "Northern Mariana Islands (Former Mariana Islands District - Trust Territory of the Pacific Islands)",
                "code": "MP",
                "dialing_code": "1-670"
            },
            {
                "country": "Norway ",
                "code": "NO",
                "dialing_code": "47"
            },
            {
                "country": "Oman, Sultanate of (Former Muscat and Oman)",
                "code": "OM",
                "dialing_code": "968"
            },
            {
                "country": "Pakistan (Former West Pakistan)",
                "code": "PK",
                "dialing_code": "92"
            },
            {
                "country": "Palau (Former Palau District - Trust Terriroty of the Pacific Islands)",
                "code": "PW",
                "dialing_code": "680"
            },
            {
                "country": "Palestinian State (Proposed)",
                "code": "PS",
                "dialing_code": "970"
            },
            {
                "country": "Panama ",
                "code": "PA",
                "dialing_code": "507"
            },
            {
                "country": "Papua New Guinea (Former Territory of Papua and New Guinea)",
                "code": "PG",
                "dialing_code": "675"
            },
            {
                "country": "Paraguay ",
                "code": "PY",
                "dialing_code": "595"
            },
            {
                "country": "Peru ",
                "code": "PE",
                "dialing_code": "51"
            },
            {
                "country": "Philippines ",
                "code": "PH",
                "dialing_code": "63"
            },
            {
                "country": "Poland ",
                "code": "PL",
                "dialing_code": "48"
            },
            {
                "country": "Portugal ",
                "code": "PT",
                "dialing_code": "351"
            },
            {
                "country": "Puerto Rico ",
                "code": "PR",
                "dialing_code": "+1-787 or +1-939"
            },
            {
                "country": "Qatar, State of ",
                "code": "QA",
                "dialing_code": "974"
            },
            {
                "country": "Reunion (French) (Former Bourbon Island)",
                "code": "RE",
                "dialing_code": "262"
            },
            {
                "country": "Romania ",
                "code": "RO",
                "dialing_code": "40"
            },
            {
                "country": "Russian Federation ",
                "code": "RU",
                "dialing_code": "7"
            },
            {
                "country": "Rwanda (Rwandese Republic) (Former Ruanda)",
                "code": "RW",
                "dialing_code": "250"
            },
            {
                "country": "Saint Helena ",
                "code": "SH",
                "dialing_code": "290"
            },
            {
                "country": "Saint Kitts and Nevis (Former Federation of Saint Christopher and Nevis)",
                "code": "KN",
                "dialing_code": "1-869"
            },
            {
                "country": "Saint Lucia ",
                "code": "LC",
                "dialing_code": "1-758"
            },
            {
                "country": "Saint Pierre and Miquelon ",
                "code": "PM",
                "dialing_code": "508"
            },
            {
                "country": "Saint Vincent and the Grenadines ",
                "code": "VC",
                "dialing_code": "1-784"
            },
            {
                "country": "Samoa (Former Western Samoa)",
                "code": "WS",
                "dialing_code": "685"
            },
            {
                "country": "San Marino ",
                "code": "SM",
                "dialing_code": "378"
            },
            {
                "country": "Sao Tome and Principe ",
                "code": "ST",
                "dialing_code": "239"
            },
            {
                "country": "Saudi Arabia ",
                "code": "SA",
                "dialing_code": "966"
            },
            {
                "country": "Senegal ",
                "code": "SN",
                "dialing_code": "221"
            },
            {
                "country": "Seychelles ",
                "code": "SC",
                "dialing_code": "248"
            },
            {
                "country": "Sierra Leone ",
                "code": "SL",
                "dialing_code": "232"
            },
            {
                "country": "Singapore ",
                "code": "SG",
                "dialing_code": "65"
            },
            {
                "country": "Slovakia",
                "code": "SK",
                "dialing_code": "421"
            },
            {
                "country": "Slovenia ",
                "code": "SI",
                "dialing_code": "386"
            },
            {
                "country": "Solomon Islands (Former British Solomon Islands)",
                "code": "SB",
                "dialing_code": "677"
            },
            {
                "country": "Somalia (Former Somali Republic, Somali Democratic Republic) ",
                "code": "SO",
                "dialing_code": "252"
            },
            {
                "country": "South Africa (Former Union of South Africa)",
                "code": "ZA",
                "dialing_code": "27"
            },
            {
                "country": "Spain ",
                "code": "ES",
                "dialing_code": "34"
            },
            {
                "country": "Sri Lanka (Former Serendib, Ceylon) ",
                "code": "LK",
                "dialing_code": "94"
            },
            {
                "country": "Sudan (Former Anglo-Egyptian Sudan) ",
                "code": "SD",
                "dialing_code": "249"
            },
            {
                "country": "Suriname (Former Netherlands Guiana, Dutch Guiana)",
                "code": "SR",
                "dialing_code": "597"
            },
            {
                "country": "Swaziland, Kingdom of ",
                "code": "SZ",
                "dialing_code": "268"
            },
            {
                "country": "Sweden ",
                "code": "SE",
                "dialing_code": "46"
            },
            {
                "country": "Switzerland ",
                "code": "CH",
                "dialing_code": "41"
            },
            {
                "country": "Syria (Syrian Arab Republic) (Former United Arab Republic - with Egypt)",
                "code": "SY",
                "dialing_code": "963"
            },
            {
                "country": "Taiwan (Former Formosa)",
                "code": "TW",
                "dialing_code": "886"
            },
            {
                "country": "Tajikistan (Former Tajik Soviet Socialist Republic)",
                "code": "TJ",
                "dialing_code": "992"
            },
            {
                "country": "Tanzania, United Republic of (Former United Republic of Tanganyika and Zanzibar)",
                "code": "TZ",
                "dialing_code": "255"
            },
            {
                "country": "Thailand (Former Siam)",
                "code": "TH",
                "dialing_code": "66"
            },
            {
                "country": "Tokelau ",
                "code": "TK",
                "dialing_code": "690"
            },
            {
                "country": "Tonga, Kingdom of (Former Friendly Islands)",
                "code": "TO",
                "dialing_code": "676"
            },
            {
                "country": "Trinidad and Tobago ",
                "code": "TT",
                "dialing_code": "1-868"
            },
            {
                "country": "Tunisia ",
                "code": "TN",
                "dialing_code": "216"
            },
            {
                "country": "Turkey ",
                "code": "TR",
                "dialing_code": "90"
            },
            {
                "country": "Turkmenistan (Former Turkmen Soviet Socialist Republic)",
                "code": "TM",
                "dialing_code": "993"
            },
            {
                "country": "Turks and Caicos Islands ",
                "code": "TC",
                "dialing_code": "1-649"
            },
            {
                "country": "Tuvalu (Former Ellice Islands)",
                "code": "TV",
                "dialing_code": "688"
            },
            {
                "country": "Uganda, Republic of",
                "code": "UG",
                "dialing_code": "256"
            },
            {
                "country": "Ukraine (Former Ukrainian National Republic, Ukrainian State, Ukrainian Soviet Socialist Republic)",
                "code": "UA",
                "dialing_code": "380"
            },
            {
                "country": "United Arab Emirates (UAE) (Former Trucial Oman, Trucial States)",
                "code": "AE",
                "dialing_code": "971"
            },
            {
                "country": "United Kingdom (Great Britain / UK)",
                "code": "GB",
                "dialing_code": "44"
            },
            {
                "country": "United States ",
                "code": "US",
                "dialing_code": "1"
            },
            {
                "country": "Uruguay, Oriental Republic of (Former Banda Oriental, Cisplatine Province)",
                "code": "UY",
                "dialing_code": "598"
            },
            {
                "country": "Uzbekistan (Former UZbek Soviet Socialist Republic)",
                "code": "UZ",
                "dialing_code": "998"
            },
            {
                "country": "Vanuatu (Former New Hebrides)",
                "code": "VU",
                "dialing_code": "678"
            },
            {
                "country": "Vatican City State (Holy See)",
                "code": "VA",
                "dialing_code": "418"
            },
            {
                "country": "Venezuela ",
                "code": "VE",
                "dialing_code": "58"
            },
            {
                "country": "Vietnam ",
                "code": "VN",
                "dialing_code": "84"
            },
            {
                "country": "Virgin Islands, British ",
                "code": "VI",
                "dialing_code": "1-284"
            },
            {
                "country": "Virgin Islands, United States (Former Danish West Indies) ",
                "code": "VQ",
                "dialing_code": "1-340"
            },
            {
                "country": "Wallis and Futuna Islands ",
                "code": "WF",
                "dialing_code": "681"
            },
            {
                "country": "Yemen ",
                "code": "YE",
                "dialing_code": "967"
            },
            {
                "country": "Zambia, Republic of (Former Northern Rhodesia) ",
                "code": "ZM",
                "dialing_code": "260"
            },
            {
                "country": "Zimbabwe, Republic of (Former Southern Rhodesia, Rhodesia) ",
                "code": "ZW",
                "dialing_code": "263"
            }
        ],
        previewed_items: {
            client: {}
        },
    }),

    computed: {

        formTitle() {
            return (this.datatables.clients.edited_index === -1 ? this.$i18n.t("crear_nuevo") : this.$i18n.t("editar")) + " " + this.$i18n.t("usuario");
        },

        statuses() {
            return JSON.parse(localStorage.getItem("statuses")).filter(function(item) {
                return ["activo", "inactivo"].includes(item.value)
            })
        }

    },

    watch: {
        "dialog.client.edit": function(val) {
            val || this.closeEdit();
        },
        "dialog.client.delete": function(val) {
            val || this.closeDelete();
        },
        "datatables.clients.options": {
            handler() {
                this.getDataFromApi()
            },
            deep: true,
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {

            this.datatables.clients.headers = [{
                    text: this.i18n.t("nombre"),
                    align: "start",
                    value: "name",
                },
                { text: this.i18n.t("documento"), value: "document" },
                { text: this.i18n.t("fecha_de_nacimiento"), value: "birthday" },
                { text: this.i18n.t("telefono"), value: "phone" },
                { text: this.i18n.t("email"), value: "email" },
                { text: this.i18n.t("estado"), value: "status" },
                { text: this.i18n.t("acciones"), value: "actions", sortable: false },
            ]

            this.setTitle(this.i18n.t("clientes"))

        },

        getDataFromApi() {

            this.setLoading(true)

            this.$axios({
                    url: "clients",
                    method: "GET",
                    params: this.datatables.clients.options
                }).then(response => {
                    this.clients = response.data.data
                    this.datatables.clients.total_items = response.data.total
                    this.datatables.clients.page_count = response.data.last_page
                    this.all_tags = response.data.all_tags
                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        editItemDialog(item) {

            this.datatables.clients.edited_index = this.clients.indexOf(item);

            if (this.datatables.clients.edited_index == -1) {
                this.edited_item.client = Object.assign({}, this.default_items.client);
            } else {
                this.edited_item.client = Object.assign({}, item);
            }

            this.dialogs.client.edit = true;

        },

        closeClientProfileDialog() {
            this.dialogs.client.profile = false
            this.previewed_items.client = Object.assign({}, this.default_items.client)
        },

        previewClientProfileDialog(item) {

            this.$axios({
                    url: "clients/profile",
                    method: "GET",
                    params: {
                        id: item.id
                    }
                })
                .then((response) => {
                    this.previewed_items.client = response.data.data
                    this.dialogs.client.profile = true
                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });

        },

        deleteItemDialog(item) {
            if (item.reservation_count > 0) {
                this.showAlert(this.$i18n.t("no_se_puede_eliminar_cliente_en_asociado_a_reservas"), this.$i18n.t("informacion"), "info")
                return;
            }
            this.datatables.clients.edited_index = this.clients.indexOf(item);
            this.edited_item.client = Object.assign({}, item);
            this.dialogs.client.delete = true;
        },

        deleteItem() {

            let _edited_index = this.datatables.clients.edited_index

            this.$axios({
                    url: "clients",
                    method: "DELETE",
                    params: {
                        id: this.edited_item.client.id
                    }
                })
                .then(() => {
                    this.clients.splice(_edited_index, 1);
                    this.closeDelete();
                })
                .catch(e => {
                    this.validation_errors = this.getValidationErrorsHtml(e)
                })

        },

        closeEdit() {
            this.dialogs.client.edit = false;
            this.$nextTick(() => {
                this.edited_item.client = Object.assign({}, this.default_items.client);
                this.datatables.clients.edited_index = -1;
            });
        },

        closeDelete() {
            this.dialogs.client.delete = false;
            this.$nextTick(() => {
                this.edited_item.client = Object.assign({}, this.default_items.client);
                this.datatables.clients.edited_index = -1;
            });
        },

        saveItem() {

            this.loading = true

            if (this.datatables.clients.edited_index > -1) {

                let _edited_index = this.datatables.clients.edited_index

                if (this.isEmpty(this.edited_item.client.name)) {
                    this.edited_item.client.name = this.i18n.t("cliente") + " " + Date.now()
                }

                if (this.isEmpty(this.edited_item.client.status)) {
                    this.edited_item.client.status = "activo"
                }

                if (this.isEmpty(this.edited_item.client.country)) {
                    this.showAlert(this.i18n.t("debe_especificar_el_pais"))
                    return
                }

                this.$axios({
                        url: "clients",
                        method: "PATCH",
                        params: {
                            id: this.edited_item.client.id
                        },
                        data: this.edited_item.client
                    })
                    .then(response => {

                        if (response.status == 200) {
                            Object.assign(this.clients[_edited_index], this.edited_item.client)
                            this.closeEdit();
                        }

                        this.loading = false
                        this.validation_errors = null
                    })
                    .catch(e => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                    })

            } else {

                this.loading = true

                if (this.isEmpty(this.edited_item.client.name)) {
                    this.edited_item.client.name = this.i18n.t("cliente") + " " + Date.now()
                }

                if (this.isEmpty(this.edited_item.client.status)) {
                    this.edited_item.client.status = "activo"
                }

                this.$axios({
                        url: "clients",
                        method: "PUT",
                        data: this.edited_item.client
                    })
                    .then(response => {

                        if (response.status == 200) {

                            this.closeEdit();
                            this.getDataFromApi()

                        }

                        this.loading = false
                        this.validation_errors = null

                    })
                    .catch(e => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                    })

            }
        },

    },
};