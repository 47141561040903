<template>
  <v-container class="fill-height fluid">
    <v-row align="center" justify="center">
      <v-col>
        <v-card class="mx-auto" max-width="374">

          <v-card-title>{{ $t("iniciar_sesion") }}</v-card-title>

          <v-card-text>

            <v-form ref="form">

              <v-text-field
                id="email"
                v-model="form.email"
                type="email"
                :label="$t('email')"
                :placeholder="$t('ingrese_email_mensaje')"
                required
              ></v-text-field>

              <v-text-field
                id="contrasena"
                v-model="form.password"
                :label="$t('contraseña')"
                required
                type="password"
              ></v-text-field>

              <v-btn color="success" @click="submit">
                {{ $t("iniciar_sesion") }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "signin",
  components: {},

  data: () => ({
      form: {
        email: null,
        password: null,
      }
  }),

  created() {
    this.initialize()
  },

  methods: {
    
    ...mapActions({
      signIn: "auth/signIn",
      authenticated: "auth/authenticated",
    }),

    initialize(){
      this.form.email = process.env.VUE_APP_LOGIN_USER
      this.form.password = process.env.VUE_APP_LOGIN_PASSWORD
    },

    submit() {
      this.signIn(this.form)
        .then((result) => {
          if (result) {
            this.$router.replace({
              name: "notes",
              path: "/",
            });
          } else {
            console.log("Login");
          }
        })
        .catch((e) => {
          switch (e.response.status) {
            case 401:
              if(e.response.data.message){
                this.$swal.fire( this.$t("error"), this.$t(e.response.data.message) || e.message, 'error' )
              }
              break;
          } // switch
        });
    },
  },
};
</script>