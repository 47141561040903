<template>
  <div>
    <v-container>
      <div class="pa-3 pl-5">
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="datatables.clients.options.search"
              append-icon="mdi-magnify"
              :label="$t('ingrese_terminos_busqueda')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              :items="[5,10,15,20,25,30,50,100,200,500,1000]"
              append-icon="mdi-filter"
              :value="datatables.clients.items_per_page?datatables.clients.items_per_page:50"
              @input="datatables.clients.items_per_page = parseInt($event, 50); datatables.clients.options.itemsPerPage = parseInt($event, 50)"
            ></v-select>
          </v-col>

        </v-row>

        <v-dialog 
          v-model="dialogs.client.edit"  
          max-width="600px"
          persistent
          >
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="edited_item.client.name"
                      :label="i18n.t('nombre')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12"  sm="6">
                    <v-text-field
                      dense
                      v-model="edited_item.client.document"
                      :label="i18n.t('documento')"
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12"  sm="6">
                    <v-text-field
                      dense
                      v-model="edited_item.client.birthday"
                      :label='i18n.t("fecha_de_nacimiento")'
                      prepend-icon="mdi-calendar"
                      type="date"
                    ></v-text-field>
                  </v-col>  
                  <v-col cols="12" sm="6">
                    <v-select
                      dense
                      v-model="edited_item.client.sex"
                      :label="i18n.t('sexo')"
                      :items="sexes"
                    ></v-select>
                  </v-col>                  
                  <v-col cols="12" sm="6">
                    <v-text-field
                      dense
                      v-model="edited_item.client.phone"
                      :label="i18n.t('telefono_movil')"
                      type="number"
                      min="3000000000"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12">
                    <v-combobox
                      v-model="edited_item.client.country"
                      :items="all_countries"
                      item-value="code"
                      item-text="country"
                      dense
                      :label="$t('pais')"
                      chips 
                    >
                    </v-combobox>
                  </v-col> 

                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="edited_item.client.phone_alternative"
                      :label="i18n.t('telefono_alternativo')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                  
                <v-row>
                  <v-col>
                    <v-combobox
                      v-model="edited_item.client.tags"
                      :items="all_tags"
                      item-value="id"
                      item-text="name"
                      chips
                      clearable
                      multiple
                      dense
                      :label="$t('tags')"      
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="edited_item.client.tags.splice(edited_item.client.tags.indexOf(item), 1); edited_item.client.tags = [...edited_item.client.tags]"
                          :color="item.color"
                          dark
                        >
                          <strong>{{ item.name || item }}</strong>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6">
                    <v-checkbox
                      dense
                      v-model="edited_item.client.edit_password"
                      :label="i18n.t('modificar_contraseña')"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      dense
                      v-model="edited_item.client.status"
                      :label="i18n.t('estado')"
                      :items="statuses"
                      item-text="text"
                      item-value="value"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" v-if="edited_item.client.edit_password"  sm="6">
                    <v-text-field
                      id="password"
                      dense
                      v-model="edited_item.client.new_password"
                      :label="i18n.t('contraseña')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" v-if="edited_item.client.edit_password" sm="6">
                    <v-text-field
                      id="password_2"
                      dense
                      v-model="edited_item.client.new_confirm_password"
                      :label="i18n.t('contraseña')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                      <v-text-field
                        dense
                        v-model="edited_item.client.email"
                        :label="i18n.t('email')"
                        required
                        type="email"
                      ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12">
                      <v-text-field
                        dense
                        v-model="edited_item.client.observations"
                        :label="i18n.t('observaciones')"
                        type="text"
                      ></v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="validation_errors">
                  <v-col>
                    <span v-html="validation_errors"></span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="cancel-button darken-1"  @click="closeEdit"> {{ $t("cancelar")}} </v-btn>
              <v-btn dark color="save-button darken-1"  @click="saveItem"> {{ $t("guardar")}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.client.delete" max-width="300px">
          <v-card>
            <v-card-title class="headline"
              >{{ $t("seguro_deseas_eliminar_registro") }}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{ $t("cancelar")}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItem"
                >{{ $t("eliminar") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.client.profile" persistent>
          <ClientProfile :client="previewed_items.client">
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                color="cancel-button darken-1"
                @click="closeClientProfileDialog()"
              >
                {{ $t("cancelar") }}
              </v-btn>
            </template>
          </ClientProfile>
        </v-dialog>

      </div>
      <v-data-table
        :headers="datatables.clients.headers"
        :items="clients"
        :options.sync="datatables.clients.options"
        :server-items-length="datatables.clients.total_items"
        sort-by="name"
        class="elevation-1"
        dense
        :search="datatables.clients.options.search"
        :page.sync="datatables.clients.page"
        :items-per-page="datatables.clients.items_per_page"
        hide-default-footer
        mobile-breakpoint="0"
        item-key="id"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2 edit-button--text" @click="editItemDialog(item)"> mdi-file-document-edit</v-icon>
          <v-icon 
            medium class="delete-button--text" 
            v-if="item.pending_reservation_count == 0 && item.confirmed_reservation_count == 0"  
            @click="deleteItemDialog(item)"
          > mdi-delete </v-icon>
          <v-icon 
            medium
            v-if="item.pending_reservation_count > 0"  
          > mdi-clock-alert </v-icon>
        </template>

        <template v-slot:no-data>
          {{ $t("sin_datos")}}
        </template>

        <template v-slot:[`item.name`]="{ item }">
          
          <span
            class="link"
            @click="previewClientProfileDialog(item)"
          >
            {{ item.name }}
          </span>
        </template>
        
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            :color="getStatusColor(item.status)"
            dark
          >
            {{ $t("estados." + item.status) }}
          </v-chip>
        </template>

      </v-data-table>
      
      <v-pagination
        v-model="datatables.clients.page"
        :length="datatables.clients.page_count"
      ></v-pagination>

    </v-container>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="true"
          color="blue"
          dark
          fixed
          bottom
          right
          class="fab-custom"
          fab
          @click="editItemDialog()"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-creation</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("nuevo") }}</span>
    </v-tooltip>
    
  </div>
</template>


<script>
  import client from '@/views/Client/Clients'
  export default client
</script>