<template>
  <div>
    <v-container>
      <div class="pa-3 pl-5">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="datatables.notes.options.search"
              append-icon="mdi-magnify"
              :label="$t('ingrese_terminos_busqueda')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="4" >
            <v-text-field
              :label="$t('desde') + '...'"
              v-model="datatables.notes.options.date_from"
              type="date"
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field
              :label="$t('hasta') + '...'"
              v-model="datatables.notes.options.date_to"
              type="date"
            ></v-text-field>
          </v-col>

        </v-row>

      </div>
      <v-data-table
        :headers="datatables.notes.headers"
        :items="notes"
        :options.sync="datatables.notes.options"
        :server-items-length="datatables.notes.total_items"
        sort-by="name"
        class="elevation-1"
        dense
        :search="datatables.notes.options.search"
        :page.sync="datatables.notes.page"
        :items-per-page="datatables.notes.items_per_page"
        hide-default-footer
        mobile-breakpoint="0"
        item-key="id"
      >
        <template v-slot:[`item.actions`]="{ item }">
          
          <v-icon 
            medium class="delete-button--text" 
            @click="deleteItem(item)"
          > mdi-delete </v-icon>
          
        </template>

        <template v-slot:no-data>
          {{ $t("sin_datos")}}
        </template>        

      </v-data-table>

    </v-container>    
    
  </div>
</template>


<script>
  import prospect_note from '@/views/Prospect/ProspectNotes'
  export default prospect_note
</script>