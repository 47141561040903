import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
          dark: {
            primary: colors.blue.darken1, // #E53935
            secondary: colors.red.lighten4, // #FFCDD2
            accent: colors.indigo.base, // #3F51B5,
            "view-button": colors.pink.base,
            "edit-button": colors.indigo.base,
            "delete-button": colors.red,
            "save-button": colors.green,
            "cancel-button": colors.red.darken4
          },
        },
    },
})