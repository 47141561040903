export default {
    methods: {
        /**
         * Añade o elimina un objeto de un array de objetos. Si el objeto ya se encuentra, lo elimina, si no, lo añade
         * @param {array} _array 
         * @param {object} _object 
         * @returns boolean
         */
        addOrRemoveObjectFromArray(_array, _object) {
            try {
                if (this.isEmpty(_array)) {
                    _array.push(_object)
                    return true
                }

                var selected_item_index = _array.findIndex(x => x.id === _object.id)
                if (selected_item_index > -1) {
                    _array.splice(selected_item_index, 1)
                    return true
                }

                _array.push(_object)

            } catch (error) {

                console.log(error)
                return false

            }
        }
    }
}