<template>
  <div>
    <v-row dense>
      <v-col cols="12" class="mt-3">
        <v-alert
          border="right"
          colored-border
          type="error"
          elevation="2"
          v-if="pendingBalance() > 0"
        >
          {{ $t("saldo_pendiente") }} : {{ formatCurrency(pendingBalance()) }}
        </v-alert>

        <v-alert
          border="right"
          colored-border
          type="warning"
          elevation="2"
          v-else-if="pendingBalance() < 0"
        >
          {{ $t("el_saldo_de_este_cliente_requiere_atencion") }} : {{ formatCurrency(pendingBalance()) }}
        </v-alert>

        <v-alert
          border="right"
          colored-border
          type="success"
          elevation="2"
          v-else
        >
          {{ $t("este_cliente_no_tiene_saldo_pendiente") }}
        </v-alert>

      </v-col>

      <v-col 
        cols="12"
        v-if="pendingBalance() > 0"
      >

        <span>{{ $t("cuanto_desea_abonar") }}</span>
        <span
          v-for="(rate, index) in [pendingBalance(), 20000, 30000, 40000, 50000, 100000, 200000, 500000]"
          :key="index"
        >
          <v-chip
            class="ma-2"
            @click="payment.value = rate"
            v-if="rate <= pendingBalance()"
          >{{ formatCurrency(rate) }}
          </v-chip>
        </span>

      </v-col>


      <v-col cols="12" sm="3">
        <v-text-field
          :label="i18n.t('ingrese_valor_a_abonar')"
          outlined
          :min="1"
          :max="pendingBalance()"
          v-model="payment.value"
          :hint="i18n.t('ingrese_valor_a_abonar')"
          type="number"
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          :label="i18n.t('observaciones')"
          v-model="payment.notes"
          :hint="i18n.t('observaciones')"
          type="text"
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-text-field
          outlined
          :label="i18n.t('comprobante')"
          v-model="payment.voucher"
          :hint="i18n.t('comprobante')"
          type="text"
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          outlined
          v-model="payment.payment_medium"
          item-text="display"
          item-value="value"
          value="transferencia"
          dense
          :items="[
            {display: 'En efectivo', value: 'efectivo'},
            {display: 'Transferencia', value: 'transferencia'},
            {display: 'Pago con saldo a favor', value: 'saldo_a_favor'},
            {display: 'Consignación', value: 'consignacion'},
            {display: 'No aplica', value: 'no_aplica'}
          ]"
        ></v-select>
      </v-col>


      
    </v-row>
    

    <v-row v-if="validation_errors">
      <v-col>
        <span v-html="validation_errors"></span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" md="2" class="mb-2">
        <v-btn
          color="success"
          @click="savePayment()"
        >
          {{ $t("guardar")}}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import PaymentForm from "@/components/Payment/PaymentForm.js";
  export default PaymentForm;
</script>
