export default {
    methods: {

        ytEmbedCodeFromUrl(url, style = "width: 100%;") {
            var video_id = url.split('v=')[1];
            var ampersandPosition = video_id.indexOf('&');
            if (ampersandPosition != -1) {
                video_id = video_id.substring(0, ampersandPosition);
            }
            return "<iframe frameborder='0' allowfullscreen='true' style='" + style + "' src='https://youtube.com/embed/" + video_id + "'></iframe>"
        },

        getPrimaryPictureUrl(item) {

            let primary_pictures = item.pictures.filter(function(
                picture
            ) {
                return picture.primary
            });

            if (this.isEmpty(primary_pictures)) {
                return "https://via.placeholder.com/150x100"
            }

            return primary_pictures[0].uploaded ? process.env.VUE_APP_REMOTE_WEB_BASE_URL + primary_pictures[0].url : primary_pictures[0].url
        },

        getImageUrl(picture) {
            return picture.uploaded ? process.env.VUE_APP_REMOTE_WEB_BASE_URL + picture.url : picture.url
        },
    }
}