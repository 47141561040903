import colorHelper from '@/libs/helpers/colorHelper'
import validationHelper from '@/libs/helpers/validationHelper'
import globalDataHelper from '@/libs/helpers/globalDataHelper'

import dateStatic from '../../libs/functions/dateStatic';

export default {
    mixins: [
        colorHelper,
        validationHelper,
        globalDataHelper
    ],
    name: "conversations",
    data: () => ({
        datatables: {
            messages: {
                options: {
                    itemsPerPage: 10000,
                    search: ''
                }
            },
            prospects: {
                options: {
                    itemsPerPage: 20
                }
            }
        },
        messages: [],
        prospects: [],
        search: '',
        current_prospect: {
            phone: null
        },
        current_origin: null,
        available_origins: []
    }),

    watch: {
        "datatables.prospects.search": {
            handler() {
                this.fetchProspects()
            }
        },
        "current_prospect": {
            handler() {
                if (this.current_prospect.phone) {
                    this.fetchMessages()
                }
            },
            deep: true
        }
    },

    created() {
        this.current_prospect.phone = this.$route.params.phone ? this.$route.params.phone : null
        this.initialize();
    },

    methods: {

        initialize() {
            this.setTitle(this.i18n.t("notas"))

        },

        fetchMessages() {

            this.messages = []
            this.available_origins = []

            this.setLoading(true)

            this.$axios({
                url: "prospects/messages",
                method: "GET",
                params: {
                    phone: this.current_prospect.phone,
                    origin: this.current_origin
                }
            }).then(response => {
                this.messages = response.data.messages
                this.available_origins = response.data.origins
                this.current_origin = null
            })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        fetchProspects() {

            this.$axios({
                url: "prospects",
                method: "GET",
                params: {
                    search: this.datatables.prospects.search
                }
            })
                .then((response) => {
                    this.prospects = response.data;

                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                    this.selected_items.reservations = []
                });
        },

        formatDate(date) {
            return dateStatic.toDateStrig(date);
        },

        getConversationImageUrl(message, image) {
            return process.env.VUE_APP_WEB_BASE_URL + 'uploads/conversations/' + message.phone + '_' + message.direction + '_' + message.origin + '_' + image.key + '.webp'
        },

        getFilteredMessages() {
            let filtered = this.messages.filter(
                (m) => {
                    return m.origin == this.current_origin
                }
            )
            console.log(filtered)
            return filtered;
        }

    },
};