export default {
    methods: {
        formatCurrency(value = 0, locale = 'es-co', format = 'currency', curr_code = 'COP') {

            if ( isNaN( value ) ) {
                value = parseFloat( value );
            }
        
            return (
                new Intl.NumberFormat(locale, {
                style: format,
                currency: curr_code,
                minimumFractionDigits: 0
                }).format(value) 
            );
        }
        
    }
}