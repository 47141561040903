import ClientProfile from "@/components/Client/Profile"
import arrayHelper from '@/libs/helpers/arrayHelper'
import colorHelper from '@/libs/helpers/colorHelper'
import fileHelper from "@/libs/helpers/fileHelper"
import globalDataHelper from '@/libs/helpers/globalDataHelper'
import jsonHelper from '@/libs/helpers/jsonHelper'
import multimediaHelper from "@/libs/helpers/multimediaHelper"
import numberHelper from "@/libs/helpers/numberHelper"
import validationHelper from '@/libs/helpers/validationHelper'
import { Blockquote, Bold, BulletList, HardBreak, Heading, History, Italic, Link, ListItem, OrderedList, Paragraph, Strike, TiptapVuetify, Underline } from 'tiptap-vuetify'

import PaymentList from '@/components/Payment/PaymentList.vue'
import PaymentForm from '@/components/Payment/PaymentForm.vue'

export default {
    mixins: [
        colorHelper,
        validationHelper,
        jsonHelper,
        arrayHelper,
        globalDataHelper,
        multimediaHelper,
        numberHelper,
        fileHelper
    ],
    name: "reservations",
    components: {
        TiptapVuetify,
        ClientProfile,
        PaymentList,
        PaymentForm
    },
    data: () => ({
        datatables: {
            reservations: {
                items_per_page: 200,
                options: {
                    show_cancelled: false,
                    show_trashed: false,
                    bus_number: 0
                },
                headers: {}
            },
            sales: {
                items_per_page: 200,
                options: {
                    show_cancelled: false,
                    show_trashed: false,
                    bus_number: 0
                },
                headers: {}
            },
            reservation_groups: {}
        },
        dialogs: {
            reservations: {
                delete: false,
                edit: false,
                preview: false
            },
            payments: {
                edit: false,
                delete: false
            },
            fabs: {
                tools: true
            },
            clients: {
                profile: false
            }
        },
        previewed_items: {
            client: {}
        },
        edited_items: {
            reservation: {
                event: {
                    rates: []
                },
                seat: 0,
                value: 0,
                accom_type: null,
                group: {},
                status: 'activo',
                client: {
                    name: null
                },
                group_color: null
            },
            payment: {
                id: 0,
                value: 0,
                reservation_id: null,
                payment_medium: "transferencia",
                platform: "panel"
            },
            new_client: null
        },
        default_items: {
            reservation: {
                event: {
                    rates: []
                },
                seat: 0,
                value: 0,
                accom_type: null,
                status: 'borrador',
                client: {
                    name: null
                },
                group_color: null
            },
            reservation_group: {},
            payment: {
                id: 0,
                value: 0,
                reservation_id: null,
                payment_medium: "transferencia",
                platform: "panel"
            },
            client: {
                id: 0,
                name: ""
            }
        },
        reservations: [],
        clients: [],
        reservation_groups: [],
        event: {
            rates: [],
            bus_count: 0
        },
        selected_items: {
            reservations: [],
            selected_rate: null
        },
        richTextEditorExtensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList, [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Paragraph,
            HardBreak
        ],
        expanded: []
    }),

    computed: {
        reservationStatuses() {
            return JSON.parse(localStorage.getItem("statuses")).filter(function(
                item
            ) {
                return ["activo", "cancelado", "borrador"].includes(item.value);
            });
        }
    },

    watch: {

        "dialog.reservations.edit": function(val) {
            val || this.closeEdit();
        },
        "dialog.reservations.delete": function(val) {
            val || this.closeDelete();
        },
        "datatables.sales.options": {
            handler() {
                this.getDataFromApi()
                this.$forceUpdate()
            },
            deep: true,
        },
        "datatables.clients.search": function() {
            this.getClientsFromApi()
        },
        model (val, prev) {
          if (val.length === prev.length) return
  
          this.model = val.map(v => {
            if (typeof v === 'string') {
              v = {
                name: v,
                vlue: v,
              }
  
              this.event.rates.push(v)
            }
  
            return v
          })
        },
      },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {

            this.datatables.sales.headers = [
                { text: "", align: "start",  width: "100px", value: "data-table-expand" },
                { text: this.i18n.t("titular"), width: "180px", align: "center", value: "main_client" },
                { text: this.i18n.t("opciones"), width: "180px", align: "center", value: "options" },
                { text: this.i18n.t("pasajeros"), width: "110px", align: "center", value: "user_count" },
                { text: this.i18n.t("valor_total"), width: "120px", align: "center", value: "total_amount" },
                { text: this.i18n.t("valor_pagado"), width: "130px", align: "center", value: "paid_amount" },
                { text: this.i18n.t("saldo"), width: "130px", align: "center", value: "balance_amount" },

            ]

            if (this.$route.query.event_id !== undefined && this.$route.query.event_id > 0) {
                this.datatables.sales.options.filters = {
                    event_id: this.$route.query.event_id
                }

                //this.getReservationGroupsFromApi()

                this.getEventFromApi(() => {
                    this.default_items.reservation.event = this.event
                    
                    this.setTitle(this.i18n.t("reservas_para") + " " + this.event.name + ", " + this.event.start_date)
                })

            }

        },

        getFormTitle(context) {
            return (
                (this.datatables[context].edited_index === -1 ?
                    this.$i18n.t("crear_nuevo") :
                    this.$i18n.t("editar"))
            );
        },

        getBusList(show_all_option = false) {
            var list = []

            if (show_all_option) {
                list.push({
                    value: 0,
                    display: "Todos"
                });
            }

            for (var i = 1; i <= this.event.bus_count; i++) {
                list.push({
                    value: i,
                    display: i
                });
            }

            return list
        },

        getMainClient(item){

            if(this.isEmpty(item.reservations)){
                return "-"
            }

            let filtered = item.reservations.filter(item => item.has_group == 1)

            if(filtered.length){
                return filtered[0].client.name
            }

            return item.reservations[0].client.name
        },

        getClientBusNumber(item) {
            if (item.status !== 'activo') {
                return ''
            }

            return ' [' + item.bus_number + ']'
        },

        canPrintVoucher(sale){
            return sale.active_reservations_count > 0 && sale.paid_amount <= sale.total_amount
        },

        getUsedChairs() {
            return
        },

        getDataFromApi() {

            this.setLoading(true)

            this.$axios({
                    url: "sales",
                    method: "GET",
                    params: this.datatables.sales.options
                }).then((response) => {

                    this.jsonColumnToArray(response.data.data)

                    this.reservation_groups = response.data.data
                    this.datatables.sales.total_items = response.data.total
                    this.datatables.sales.page_count = response.data.last_page

                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        getClientsFromApi() {
            this.$axios({
                    url: "clients",
                    method: "GET",
                    params: {
                        search: this.datatables.clients.search,
                        ignore: this.getClientIdArray()
                    }
                })
                .then((response) => {
                    this.clients = response.data.data;
                    this.datatables.clients.total_items = response.data.total;

                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                    this.selected_items.reservations = []
                });
        },

        getClientIdArray(){
            let ids = [];
            this.reservation_groups.forEach(e => {
                e.reservations.forEach(r => {
                    ids.push(r.client.id);
                });
            });
            return ids;
        },
       
        getEventFromApi(callback) {
            this.$axios({
                    url: "events",
                    method: "GET",
                    params: {
                        id: this.$route.query.event_id
                    }
                })
                .then((response) => {
                    this.event = response.data.data[0];

                    if (callback) callback()

                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        handleExpansion(item, state) {
            const itemIndex = this.expanded.indexOf(item);
        
            state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
        },

        getEventSheet() {

            var _event = this.event

            this.downloadBlobFile(
                "events/print/sheet", {
                    id: _event.id,
                    bus_number: this.datatables.sales.options.bus_number
                },
                this.event.name + ' ' + this.event.start_date + '.xlsx'
            )

        },

        getEventInsuranceSheet() {

            var v = this.reservations.filter(res => {
                return (this.isEmpty(res.client.document) && res.status == "activo")
            })
            if (!this.isEmpty(v)) {
                this.showAlert(this.i18n.t("hay_documentos_vacios_en_reservas_confirmadas", [v.length]))
                return
            }

            v = this.reservations.filter(res => {
                return (res.status == "activo" && res.has_group == 1 && this.isEmpty(res.client.phone))
            })
            if (!this.isEmpty(v)) {
                this.showAlert(this.i18n.t("hay_telefonos_vacios_en_reservas_confirmadas_de_lider_grupal", [v.length]))
                return
            }

            var _event = this.event

            this.downloadBlobFile(
                "events/print/insurancesheet", {
                    id: _event.id
                },
                "Seguros - " + this.event.name + ' ' + this.event.start_date + '.xlsx'
            )

        },

        showGroupVoucher(sale) {
            window.open(process.env.VUE_APP_WEB_BASE_URL + "downloads/reservations/voucher?id=" + sale.id + "&token=" + sale.download_token, "download")
        },

        copyVoucherLink(sale) {

            var url = process.env.VUE_APP_WEB_BASE_URL + "downloads/reservations/voucher?id=" + sale.id + "&token=" + sale.download_token  + "&utm-source=sharelink&utm-medium=voucher&utm-client-segment=" + this.event.pack_type

            let a = this
            navigator.clipboard.writeText(url).then(function() {
                a.showAlert(a.i18n.t("se_copio_el_enlace_de_descarga_del_voucher_siguiente") + "<br/>" + sale.reservations.map(r => '<br>' + r.client.name), a.i18n.t("informacion"), "info")
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        
        
        getGroupVoucher(sale) {

            this.downloadBlobFile(
                "reservations/print/voucher", {
                    id: sale.id
                },
                this.event.name + ' ' + this.event.start_date + '.pdf'
            )
        },

        addSale() {
            this.$axios({
                url: "sales",
                method: "PUT",
                params: {
                    event_id: this.event.id
                }
            })
            .then(() => {
                this.getDataFromApi()
                this.validation_errors = null
            })
            .catch(e => {
                this.validation_errors = this.getValidationErrorsHtml(e)
            })
            .finally(() => {
                this.setLoading(false)
            })
        },

        addClient(sale) {
            this.$axios({
                url: "reservations",
                method: "PUT",
                params: {
                    event_id: this.event.id,
                    sale_id: sale.id,
                    client_id: this.edited_items.new_client.id
                }
            })
            .then(() => {
                this.getDataFromApi()
                this.validation_errors = null
                this.edited_items.new_client = null
            })
            .catch(e => {
                this.validation_errors = this.getValidationErrorsHtml(e)
            })
            .finally(() => {
                this.setLoading(false)
            })
        },
        updateSelectedClient(e){
            console.log(e)
        },
        
        updateReservation($event, edited_key, reservation) {
            
            this.setLoading(true)

            this.edited_items.reservation = Object.assign({}, reservation);
            var _edited_reservation = Object.assign({}, this.edited_items.reservation)

            _edited_reservation.client_id = this.edited_items.reservation.client.id
            _edited_reservation.event_id = this.edited_items.reservation.event.id
            _edited_reservation.seat = this.edited_items.reservation.seat ? 1 : 0
                        
            if(!this.isEmpty($event) && typeof($event) == 'object'){
                _edited_reservation[edited_key] = $event.value
                switch (edited_key) {
                    case 'value':
                        _edited_reservation['seat'] = $event.seat
                        break;
                    case 'meeting_point':
                        _edited_reservation['meeting_point'] = $event.reference_code
                        break;
                }
            }else{
                _edited_reservation[edited_key] = $event
            }

            switch (edited_key) {
                case 'value':
                    _edited_reservation[edited_key] = parseInt(_edited_reservation[edited_key])
                    break;
                case 'accom_type':
                case 'notes':
                    break;
                case 'seat':
                    _edited_reservation[edited_key] = _edited_reservation[edited_key]?1:0
                    break;
                case 'status':
                    if($event === 'cancelado') break;
                    _edited_reservation[edited_key] = _edited_reservation[edited_key]?'activo':'borrador'
                    break;
            }

            this.validation_errors = null

            this.$axios({
                url: "reservations",
                method: "PATCH",
                data: _edited_reservation
            })
            .then(() => {
                this.getDataFromApi()
                this.validation_errors = null
            })
            .catch(e => {
                this.validation_errors = this.getValidationErrorsHtml(e)
            })
            .finally(() => {
                this.setLoading(false)
            })

            
        },

        deleteReservation(reservation) {
            
            this.$swal({
                title: this.$t("eliminar"),
                text: this.$t("seguro_deseas_eliminar_registro"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("si"),
                cancelButtonText: this.$t("no")
              })
              .then((result) => {
                if (result.isConfirmed) {
                    this.$axios({
                        url: "reservations",
                        method: "DELETE",
                        data: {
                            id: reservation.id
                        }
                    })
                    .then(() => {
                        this.getDataFromApi()
                        this.validation_errors = null
                    })
                    .catch(e => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                        this.getDataFromApi()
                    })
                    .finally(() => {
                        this.setLoading(false)
                    })
                }
            });            
        },

        deleteSale(sale) {
            
            this.$swal({
                title: this.$t("eliminar"),
                text: this.$t("seguro_deseas_eliminar_registro"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("si"),
                cancelButtonText: this.$t("no")
              })
              .then((result) => {
                if (result.isConfirmed) {
                    this.$axios({
                        url: "sales",
                        method: "DELETE",
                        data: {
                            id: sale.id
                        }
                    })
                    .then(() => {
                        this.getDataFromApi()
                        this.validation_errors = null
                    })
                    .catch(e => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                        this.getDataFromApi()
                    })
                    .finally(() => {
                        this.setLoading(false)
                    })
                }
            });            
        },

        previewClientProfileDialog(item) {

            this.$axios({
                    url: "clients/profile",
                    method: "GET",
                    params: {
                        id: item.id
                    }
                })
                .then((response) => {
                    this.previewed_items.client = response.data.data
                    this.dialogs.clients.profile = true
                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });

        },

        closeClientProfileDialog() {
            this.dialogs.clients.profile = false
            this.previewed_items.client = Object.assign({}, this.default_items.client)
        }


    }
}