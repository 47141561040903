import colorHelper from '@/libs/helpers/colorHelper'
import validationHelper from '@/libs/helpers/validationHelper'
import globalDataHelper from '@/libs/helpers/globalDataHelper'
import jsonHelper from '@/libs/helpers/jsonHelper'
import multimediaHelper from "@/libs/helpers/multimediaHelper";
import numberHelper from "@/libs/helpers/numberHelper";
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, History } from 'tiptap-vuetify'
import NoResultsBar from "@/components/Interface/NoResultsBar";


export default {
    mixins: [
        colorHelper,
        validationHelper,
        jsonHelper,
        globalDataHelper,
        multimediaHelper,
        numberHelper
    ],
    name: "events",
    components: {
        TiptapVuetify,
        NoResultsBar

    },
    data: () => ({
        datatables: {
            events: {
                items_per_page: 50,
                options: {
                    date_from: null,
                    date_to: null,
                    status:null,
                    pack_id: null
                },
                headers: [
                    { text: 'name', align: "start", value: "name" }
                ]
            }
        },
        dialogs: {
            events: {
                delete: false,
                edit: false,
                preview: false
            }
        },
        edited_items: {
            event: {
                end_date: null,
                start_date: null
            }
        },
        default_items: {
            event: {}
        },
        override_fields: {
            event: {
                pack_type: false,
                total_distance: false,
                pictures: false,
                videos: false
            }
        },
        events: [],
        packs: [],
        selected_pack: [],
        array_columns: ['pictures', 'meeting_points'],
        previewed_items: {
            event: {}
        },
        richTextEditorExtensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList, [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Paragraph,
            HardBreak
        ],
        initialise_actions: {
            set_edited_event: true
        },
        expanded_panels: 0
    }),

    computed: {
        eventStatuses() {
            return JSON.parse(localStorage.getItem("statuses")).filter(function(
                item
            ) {
                return ["programado", "cancelado", "finalizado", "borrador"].includes(item.value);
            });
        },
    },

    watch: {
        "dialog.events.edit": function(val) {
            val || this.closeEdit();
        },
        "dialog.events.delete": function(val) {
            val || this.closeDelete();
        },
        "datatables.events.options": {
            handler() {
                this.getDataFromApi()
            },
            deep: true
        },
        "selected_pack": function(pack){
            if (pack) {
                this.datatables.events.options.pack_id = pack.id
            }
        },
        "edited_items.event.start_date": function(val) {
            if (val && this.edited_items.event.pack_type !== 'excursion') {
                this.edited_items.event.end_date = val
            }
        }
    },

    created() {
        this.initialize();
    },


    methods: {
        initialize() {

            this.getPackListFromApi()

            if(this.$route.query.pack_id !== undefined && this.$route.query.pack_id > 0) {
                this.datatables.events.options.pack_id = this.$route.query.pack_id;
                this.datatables.events.options.status = ['borrador', 'programado'];   
            }else{
                this.datatables.events.options.status = ['programado'];
            }

            this.setTitle(this.i18n.t("programacion"))


        },


        getFormTitle(context) {
            return (
                (this.datatables[context].edited_index === -1 ?
                    this.$i18n.t("crear_nuevo") :
                    this.$i18n.t("editar"))
            );
        },

        getDataFromApi(callback) {

            this.setLoading(true)

            let params = Object.assign({}, this.datatables.events.options);
            params.pack_id = this.datatables.events.options.pack_id

            this.$axios({
                    url: "events",
                    method: "GET",
                    params: params
                }).then((response) => {

                    this.jsonColumnToArray(response.data.data)

                    this.events = response.data.data
                    this.datatables.events.total_items = response.data.total
                    this.datatables.events.page_count = response.data.last_page

                    if (callback) callback()

                    if (this.$route.query.edit_event_id !== undefined && this.$route.query.edit_event_id > 0) {
                        var a = this.events.filter(obj => {
                            return obj.id === this.$route.query.edit_event_id
                        })

                        if (!this.isEmpty(a)) {
                            this.edited_items.event = a[0]
                            this.datatables.events.edited_index = this.events.indexOf(a[0])
                            this.dialogs.events.edit = true
                        }
                    }

                })
                .catch((e) => {
                    this.validation_errors = this.getValidationErrorsHtml(e);
                })
                .finally(() => {
                    this.setLoading(false)
                });
        },

        getReservationBarColor(event) {
            return this.getSeatQuota(event) >= parseInt(event.min_quota) && parseInt(event.min_quota) > 0 ?
                'green' :
                'red'
        },

        getReservationPercentage(event) {
            var percentage = Math.ceil((this.getSeatQuota(event) / parseInt(event.max_quota)) * 100) || 0
            return percentage !== Infinity && !isNaN(percentage) ?
                percentage :
                0
        },

        getSeatQuota(event) {
            var used_quota = 0
            var index = 0
            while (index <= event.reservations.length - 1) {
                if (event.reservations[index].seat && event.reservations[index].status == 'activo') {
                    used_quota++
                }
                index++
            }
            return used_quota
        },

        getReservationBarLabel(event) {
            return this.getSeatQuota(event) + " " + this.$i18n.t("de") + " " + event.max_quota + " ~ " + this.getReservationPercentage(event) + "% | " + this.getPackConfirmation(event)
        },

        getPackConfirmation(event) {
            return this.getSeatQuota(event) >= parseInt(event.min_quota) || parseInt(event.min_quota) == 0 ?
                this.$i18n.t("confirmado") :
                this.$i18n.t("sin_confirmar")
        },

        newRate() {
            this.edited_items.event.rates.push({ "name": "", "value": "0", "seat": true })
        },

        newMeetingPoint() {
            if (this.isEmpty(this.edited_items.event.meeting_points)) {
                this.edited_items.event.meeting_points = [];
            }
            this.edited_items.event.meeting_points.push({ "place": "", "reference_code": "", "time": "00:00", "departure_time": "00:00" })
        },

        editItemDialog(item) {
            this.datatables.events.edited_index = this.events.indexOf(item);

            if (this.datatables.events.edited_index == -1) {
                this.edited_items.event = Object.assign({}, this.default_items.event);
            } else {
                this.edited_items.event = Object.assign({}, item);
            }

            this.dialogs.events.edit = true;

        },

        deleteItemDialog(item) {
            this.datatables.events.edited_index = this.events.indexOf(item);
            this.edited_items.event = Object.assign({}, item);
            this.dialogs.events.delete = true;
        },

        getPackListFromApi() {

            this.$axios({
                url: "packs",
                method: "GET",
                params: {
                    itemsPerPage: 1000
                }
            }).then((response) => {
                this.packs = response.data.data
            })
            .catch((e) => {
                this.validation_errors = this.getValidationErrorsHtml(e);
            });
        },

        deleteItem() {

            let _edited_index = this.datatables.events.edited_index

            this.setLoading(true)

            this.$axios({
                    url: "events",
                    method: "DELETE",
                    params: {
                        id: this.edited_items.event.id
                    }
                })
                .then(() => {
                    this.events.splice(_edited_index, 1);
                    this.closeDelete();
                })
                .catch(e => {
                    this.validation_errors = this.getValidationErrorsHtml(e)
                })
                .finally(() => {
                    this.setLoading(false)
                })

        },

        closeEdit() {
            this.dialogs.events.edit = false;
            this.$nextTick(() => {
                this.edited_items.event = Object.assign({}, this.default_items.event);
                this.datatables.events.edited_index = -1;
            });
        },

        closeDelete() {
            this.dialogs.events.delete = false;
            this.$nextTick(() => {
                this.edited_items.event = Object.assign({}, this.default_items.event);
                this.datatables.events.edited_index = -1;
            });
        },

        closePreview() {
            this.dialogs.events.preview = false;
            this.$nextTick(() => {
                this.previewed_items.event = Object.assign({}, this.default_items.event)
                this.datatables.events.preview_index = -1;
            });
        },

        previewItemDialog(item) {
            this.datatables.events.preview_index = this.events.indexOf(item)

            this.previewed_items.event = Object.assign({}, item)

            this.dialogs.events.preview = true;
        },



        saveItem() {

            var _edited_event = Object.assign({}, this.edited_items.event)
            console.log(_edited_event)

            for (const [key, value] of Object.entries(this.override_fields.event)) {
                if (!value) {
                    delete _edited_event[key]
                }
            }

            this.setLoading(true)

            if (this.datatables.events.edited_index > -1) {

                let _edited_index = this.datatables.events.edited_index

                this.$axios({
                        url: "events",
                        method: "PATCH",
                        params: {
                            id: _edited_event.id
                        },
                        data: _edited_event
                    })
                    .then(() => {
                        Object.assign(this.events[_edited_index], _edited_event)
                        this.closeEdit();

                        this.validation_errors = null
                        this.$route.query.edit_event_id = null
                        this.getDataFromApi()
                    })
                    .catch((e) => {
                        this.validation_errors = this.getValidationErrorsHtml(e)
                    })
                    .finally(() => {
                        this.setLoading(false)
                    })

            } else {

                this.$axios({
                        url: "events",
                        method: "PUT",
                        data: _edited_event
                    })
                    .then(() => {

                        this.closeEdit();
                        this.validation_errors = null
                        this.$route.query.edit_event_id = null
                        this.getDataFromApi()

                    })
                    .catch((e) => {

                        this.validation_errors = this.getValidationErrorsHtml(e)

                    })
                    .finally(() => {
                        this.setLoading(false)
                    })

            }
        }

    },
};