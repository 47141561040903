<template>
  <v-simple-table
    dense
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th>{{ $i18n.t("acciones") }}</th>
          <th>{{ $i18n.t("plataforma") }}</th>
          <th>{{ $i18n.t("valor") }}</th>
          <th>{{ $i18n.t("comprobante") }}</th>
          <th>{{ $i18n.t("notas") }}</th>
          <th>{{ $i18n.t("fecha") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(payment) in allPayments" :key="payment.id">
          <td>
            <v-icon
              class="delete-button--text"
              :title="$t('eliminar')"
              @click="deletePayment(payment)"
            >
              mdi-delete
            </v-icon>
          </td>
          <td>{{ payment.platform }}</td>
          <td>{{ formatCurrency(payment.value) }}</td>
          <td>{{ payment.voucher }}</td>
          <td>{{ payment.notes }}</td>
          <td>{{ payment.date }}</td>
          <td></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
  import PaymentList from "@/components/Payment/PaymentList.js";
  export default PaymentList;
</script>
