export default {
    methods: {
        downloadBlobFile(request_url, request_params, save_name, method = "GET", mime_type = 'application/pdf', preview = false) {

            this.$axios({
                    url: request_url,
                    method: method,
                    params: request_params,
                    responseType: 'blob'
                })
                .then((response) => {
                    var fileURL;
                    if (!preview) {
                        fileURL = window.URL.createObjectURL(new Blob([response.data]), { type: mime_type });
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', save_name);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else {
                        var file = new Blob([response.data], { type: mime_type });
                        fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    }

                })
                .catch(e => {
                    this.validation_errors = this.getValidationErrorsHtml(e)
                })
                .finally(() => {
                    this.setLoading(false)
                })


        }
    }
}