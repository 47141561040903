//import axios from 'axios'
//import i18n from '@/plugins/i18n'

export default {
    namespaced: true,
    state: {
        is_loading: false,
        title: "",
    },
    getters: {
        isLoading(state) {
            return state.is_loading
        },
        getTitle(state) {
            return state.title
        }
    },
    mutations: {
        setLoading(state, is_loading) {
            state.is_loading = is_loading
        },
        setTitle(state, new_title) {
            state.title = new_title,
                document.title = new_title
        }
    },
    actions: {
        setLoading({ commit }, loading) {
            commit('setLoading', loading)
        },
        setTitle({ commit }, new_title) {
            commit('setTitle', new_title)
        },
    }
}