<template>
  <v-app>

    <v-overlay :value="is_loading" id="main-overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    
    <TopBar v-if="authenticated" />
    <v-main v-bind:class="applyMarginTop() ? 'mt-15':'mt-0'">
      <router-view />
    </v-main>
    <BottomBar v-if="authenticated" />
  </v-app>
</template>

<script>
import TopBar from "@/components/Interface/TopBar";
import BottomBar from "@/components/Interface/BottomBar";
import { mapGetters } from "vuex";

export default {
  components: {
    TopBar,
    BottomBar
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      is_loading: "app/isLoading"
    }),
  },

  methods: {
    applyMarginTop(){
      var disable_mt_in = ["signin"];
      if(disable_mt_in.includes(this.$route.name)){
        return false
      }
      return true
    }
  }
};
</script>
