export default (store) => {

    const ability = store.getters.ability;

    store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'auth/SET_ABILITIES':
                if (mutation.payload) {
                    localStorage.setItem('abilities', mutation.payload)
                    ability.update(JSON.parse(localStorage.getItem('abilities')))
                } else {
                    localStorage.removeItem('abilities')
                    ability.update([])
                }
                break;
            case 'auth/SET_ROLES':
                if (mutation.payload) {
                    localStorage.setItem('roles', mutation.payload)
                } else {
                    localStorage.removeItem('roles')
                }
                break;
            case 'auth/SET_STATUSES':
                if (mutation.payload) {
                    localStorage.setItem('statuses', mutation.payload)
                } else {
                    localStorage.removeItem('statuses')
                }
                break;
            case 'auth/SET_SEXES':
                if (mutation.payload) {
                    localStorage.setItem('sexes', mutation.payload)
                } else {
                    localStorage.removeItem('sexes')
                }
                break;
            case 'auth/SET_PACK_TYPES':
                if (mutation.payload) {
                    localStorage.setItem('pack_types', mutation.payload)
                } else {
                    localStorage.removeItem('pack_types')
                }
                break;
            case 'auth/SET_ACCOM_TYPES':
                if (mutation.payload) {
                    localStorage.setItem('accom_types', mutation.payload)
                } else {
                    localStorage.removeItem('accom_types')
                }
                break;
            case 'auth/SET_AGENCIES':
                if (mutation.payload) {
                    localStorage.setItem('agencies', mutation.payload)
                } else {
                    localStorage.removeItem('agencies')
                }
                break;
        }
    })
}