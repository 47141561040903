<template>
  <div>
    <v-container>
      <v-row class="px-2">
        <v-col cols="8">

          <v-text-field
            v-model="datatables.packs.options.search"
            append-icon="mdi-magnify"
            :label="$t('ingrese_terminos_busqueda')"
            single-line
            hide-details
            @input="datatables.packs.page = 1"
          ></v-text-field>
        
        </v-col>
        <v-col cols="4">
          <!-- @change="datatables.packs.items_per_page = parseInt($event, 10); datatables.packs.options.itemsPerPage = parseInt($event, 10)" -->
          <v-select
            v-model="datatables.packs.options.itemsPerPage"
            :items="[5,10,15,20,25,30,50,100,200,500,1000]"
            append-icon="mdi-filter"
          ></v-select>
        </v-col>

      </v-row>
    </v-container>

    <v-pagination
      v-model="datatables.packs.page"
      :length="datatables.packs.page_count"
      v-if="datatables.packs.total_items > 0 && datatables.packs.page_count > 1"
    ></v-pagination>
       
    <v-data-table
      :headers="datatables.packs.headers"
      :items="packs"
      :options.sync="datatables.packs.options"
      :server-items-length="datatables.packs.total_items"
      sort-by="name"
      class="elevation-1"
      item-key="id"
      hide-default-header
      hide-default-footer
      :search="datatables.packs.options.search"
      :page.sync="datatables.packs.page"
      :items-per-page="datatables.packs.items_per_page"
    >
      <template v-slot:top>
        <v-dialog 
          v-model="dialogs.packs.edit" 
          persistent
          fullscreen
        >
          <v-card>
            <v-card-title class="headline">
              {{ getFormTitle("packs") }}  {{ $i18n.t("paquete") }}
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      v-model="edited_items.pack.name"
                      :label="i18n.t('nombre')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      v-model="edited_items.pack.location"
                      :label="i18n.t('ubicacion')"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select
                      outlined
                      v-model="edited_items.pack.agency_id"
                      :label="i18n.t('agencia')"
                      :items="agencies"
                      item-text="text"
                      item-value="value"
                      :return-object="false"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select
                      outlined
                      v-model="edited_items.pack.status"
                      :label="i18n.t('estado')"
                      :items="packStatuses"
                      item-text="text"
                      item-value="value"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.short_description"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('descripcion_corta') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>
                </v-row>
                <v-row>

                <v-col cols="12" lg="3">
                  <v-select
                    outlined
                    v-model="edited_items.pack.pack_type"
                    :label="i18n.t('tipo_de_paquete')"
                    :items="pack_types"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                </v-col>

                <v-col cols="12" lg="3">
                  <v-select
                    outlined
                    v-model="edited_items.pack.transport_type"
                    :label="i18n.t('tipo_de_transporte')"
                    :items="[
                      {
                        text: i18n.t('tipos_de_transporte.aereo'),
                        value: 'aereo'
                      },
                      {
                        text: i18n.t('tipos_de_transporte.terrestre'),
                        value: 'terrestre'
                      }
                    ]"
                    item-text="text"
                    item-value="value"
                    :return-object="false"
                  ></v-select>
                </v-col>

                <v-col cols="12" lg="3">
                  <v-text-field
                    type="number"
                    min="0"
                    outlined
                    v-model="edited_items.pack.duration_in_nights"
                    :disabled="edited_items.pack.pack_type !== 'excursion'"
                    :label="i18n.t('duracion_en_noches')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" lg="3">
                  <v-text-field
                    type="text"
                    outlined
                    v-model="edited_items.pack.tag"
                    :label="i18n.t('apodo')"
                  ></v-text-field>
                </v-col>

                </v-row>
                <v-row>

                  <v-col cols="12" v-if="edited_items.pack.pack_type=='senderismo'">
                    <v-select
                      outlined
                      v-model="edited_items.pack.difficulty_level"
                      :label="i18n.t('nivel_de_dificultad')"
                      :items="[
                        '1',
                        '1.5',
                        '2',
                        '2.5',
                        '3',
                        '3.5',
                        '4',
                        '4.5',
                        '5'
                      ]"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" v-if="edited_items.pack.pack_type=='senderismo'">
                    <br>
                    <v-slider
                      v-model="edited_items.pack.total_distance"
                      :label="i18n.t('distancia_total')"
                      thumb-color="blue"
                      thumb-label="always"
                      min="1"
                      max="50"
                    ></v-slider>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.includes"
                      :extensions="richTextEditorExtensions"
                    >
                      <template #toolbar-before><label>{{ i18n.t('incluye') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.not_includes"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('no_incluye') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.itinerary"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('itinerario') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col> 

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.observations"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('observaciones') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>  

                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.recommendations"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('recomendaciones') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col>      

                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      v-model="edited_items.pack.min_quota"
                      :label="i18n.t('cupo_minimo')"
                      type="number"
                      min="1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      outlined
                      v-model="edited_items.pack.max_quota"
                      :label="i18n.t('cupo_maximo')"
                      type="number"
                      max="500"
                      :min="edited_items.pack.min_quota"
                    ></v-text-field>
                  </v-col>  

                  <v-col cols="12">                   
                    <v-btn color="primary" dark class="mb-2" @click="newRate()">
                      <v-icon>mdi-creation</v-icon><span>{{ $t("nueva_tarifa") }}</span>
                    </v-btn>
                  </v-col>

                  <v-container fluid class="container-bg px-10 py-10 rounded-lg">
                    <v-row
                        v-for="(rate, index) in edited_items.pack.rates" 
                        v-bind:key="index">
                        <v-col cols="12" md="5">
                          <v-text-field
                            outlined
                            v-model="rate.name"
                            :label="i18n.t('tarifa')"
                            type="text"
                            maxlength="30"
                            counter="30"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="9" md="2">
                          <v-text-field
                            outlined
                            v-model="rate.value"
                            :label="i18n.t('valor')"
                            type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="3" md="2">
                          <v-checkbox
                            outlined
                            v-model="rate.seat"
                            :label="i18n.t('ocupa_silla')"
                            type="number"
                          ></v-checkbox>
                        </v-col>

                        <v-col cols="10" md="2">
                          <v-select
                            outlined
                            v-model="rate.accom_type"
                            :label="i18n.t('acomodacion')"
                            item-value="value" 
                            item-text="text" 
                            :items="accom_types"
                            :disabled="edited_items.pack.pack_type!='excursion'"
                          ></v-select>
                        </v-col>

                        <v-col cols="2" md="1">
                          <v-icon
                            @click="edited_items.pack.rates.splice(index, 1)"
                            large class="delete-button--text mt-3 float-right"
                          >mdi-delete
                          </v-icon>
                        </v-col>
                        
                    </v-row>

                    <v-row v-if="isEmpty(edited_items.pack.rates)">
                      {{ $t("sin_tarifas_clic_crea_una") }}
                    </v-row>
                  </v-container>                        

                  <v-col cols="12">
                    <br>
                  </v-col>

                  <v-col cols="12">                   
                    <v-btn color="primary" dark class="mb-2" @click="newMeetingPoint()">
                      <v-icon>mdi-creation</v-icon><span>{{ $t("nuevo_punto_de_encuentro") }}</span>
                    </v-btn>
                  </v-col>  

                  <v-container fluid class="container-bg px-10 py-10 rounded-lg">
                    <v-row
                        v-for="(meeting_point, index) in edited_items.pack.meeting_points" 
                        v-bind:key="index">
                        
                        <v-col cols="12" md="10">
                          <v-text-field
                            outlined
                            v-model="meeting_point.place"
                            :label="i18n.t('lugar')"
                            type="text"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                          <v-text-field
                            outlined
                            v-model="meeting_point.reference_code"
                            :label="i18n.t('referencia')"
                            type="text"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col md="5" cols="12">
                          <v-text-field
                            outlined
                            v-model="meeting_point.time"
                            :label="i18n.t('hora_de_encuentro')"
                            type="time"
                          ></v-text-field>
                        </v-col>

                        <v-col md="5" cols="10">
                          <v-text-field
                            outlined
                            v-model="meeting_point.departure_time"
                            :label="i18n.t('hora_de_salida')"
                            type="time"
                          ></v-text-field>
                        </v-col>

                        <v-col md="2" cols="2">
                          <v-icon
                            @click="edited_items.pack.meeting_points.splice(index, 1)"
                            large class="delete-button--text mt-3 float-right"
                          >mdi-delete
                          </v-icon>
                        </v-col>
                    </v-row>

                    <v-row v-if="isEmpty(edited_items.pack.meeting_points)">
                      {{ $t("sin_puntos_de_encuentro_clic_crea_uno") }}
                    </v-row>

                  </v-container>

                  <v-col cols="12">
                    <br>
                  </v-col>

                  <v-col cols="12" class="image-upload-container">

                    <div class="image-upload">
                      <label for="image-file-input">
                        <v-icon>mdi-plus</v-icon><br>
                        {{ $t("seleccione_imagenes")}}
                      </label>

                      <input 
                        accept="image/*"
                        id="image-file-input" 
                        multiple="true"
                        type="file"
                        @change="previewPictures()"
                      />
                    </div>

                  </v-col>

                  <v-container fluid class="container-bg px-10 py-10 rounded-lg fill-height" v-if="!isEmpty(edited_items.pack.pictures)">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        class="d-flex child-flex"
                        v-for="(picture, index) in edited_items.pack.pictures"
                        v-bind:key="index"
                      >
                        <v-card class="mb-5">                            
                          <v-img
                            :src="getImageUrl(picture)"
                            max-height="220"
                            contain
                            class="grey darken-4"
                          ></v-img>

                          <v-card-actions style="display: block !important">                              
                            <v-checkbox
                              v-model="picture.primary"
                              :label="i18n.t('principal')"
                              class="float-left mt-3 ml-2 is-primary-picture-checkbox"
                              @change="setPrimaryPicture(index)"
                            ></v-checkbox>
                            <v-icon
                              @click="edited_items.pack.pictures.splice(index, 1)"
                              large class="delete-button--text mt-3 float-right"
                            >mdi-delete
                            </v-icon>
                          </v-card-actions>
                                                    
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-container>

                  <v-col cols="12">
                    <br>
                  </v-col>

                  <v-col cols="12">                   
                    <v-btn color="primary" dark class="mb-2" @click="newVideo()">
                      <v-icon>mdi-creation</v-icon><span>{{ $t("nuevo_video") }}</span>
                    </v-btn>
                  </v-col>  

                  <v-container fluid class="container-bg px-10 py-10 rounded-lg">
                    <v-row
                        v-for="(video, index) in edited_items.pack.videos" 
                        v-bind:key="index">
                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            v-model="video.title"
                            :label="i18n.t('titulo')"
                            type="text"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="10" md="5">
                          <v-text-field
                            outlined
                            v-model="video.url"
                            :label="i18n.t('direccion_youtube')"
                            type="url"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="2" md="1">
                          <v-icon
                            @click="edited_items.pack.videos.splice(index, 1)"
                            large class="delete-button--text mt-3 float-right"
                          >mdi-delete
                          </v-icon>
                        </v-col>
                    </v-row>

                    <v-row v-if="isEmpty(edited_items.pack.videos)">
                      {{ $t("sin_videos_clic_crea_uno") }}
                    </v-row>

                  </v-container>

                  <v-col cols="12">
                    <br>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      v-model="edited_items.pack.calendar_raw"
                      :label="i18n.t('programacion')"
                      outlined
                    >
                    </v-textarea>
                  </v-col>

                </v-row>                    

                <v-row>

                  <v-col cols="12" md="6" lg="3">
                    <v-select
                      v-model="edited_items.pack.calendar_format"
                      :label="i18n.t('formato_de_calendario')"
                      outlined
                      :return-object="false"
                      :items= "[
                        {
                          text: i18n.t('formatos_de_calendario.csv'),
                          value: 'csv'
                        },
                        {
                          text: i18n.t('formatos_de_calendario.tsv'),
                          value: 'tsv'
                        }
                      ]"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      v-model="edited_items.pack.increment_rate"
                      :label="i18n.t('incremento_tarifario')"
                      outlined
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" lg="3">
                    <v-checkbox
                      v-model="edited_items.pack.update_wc_images"
                      :label="i18n.t('wc.actualizar_imagenes_wc')"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" md="6" lg="3">
                    <v-checkbox
                      v-model="edited_items.pack.show_regular_price"
                      :label="i18n.t('wc.mostrar_precio_regular')"
                    ></v-checkbox>
                  </v-col>                  
                
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="edited_items.pack.web_article"
                      :extensions="richTextEditorExtensions"
                      class="tiptapeditor"
                    >
                      <template #toolbar-before><label>{{ i18n.t('articulo_web') }}</label></template>
                      <template #toolbar="{ buttons }" class="d-none">
                        {{ buttons }}
                      </template>
                    </tiptap-vuetify>
                  </v-col> 

                </v-row>



                <v-row v-if="validation_errors">
                  <v-col>
                    <span v-html="validation_errors"></span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions style="position: sticky; bottom: 0">
              <v-spacer></v-spacer>
              <v-btn dark color="cancel-button darken-1" @click="closeEdit()">
                {{ $t("cancelar") }}
              </v-btn>
              <v-btn dark color="save-button darken-1" @click="saveItem()">
                {{ $t("guardar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog v-model="dialogs.packs.delete" max-width="600px">
          <v-card>
            <v-card-title class="headline">{{
              $t("seguro_deseas_eliminar_registro")
            }}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="save-button darken-1" @click="closeDelete()">{{
                $t("cancelar")
              }}</v-btn>
              <v-btn dark color="cancel-button darken-1" @click="deleteItem()">{{
                $t("eliminar")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog 
          v-model="dialogs.packs.preview" 
          fullscreen
        >
          <v-card>
            <v-card-title class="headline">
              {{ $i18n.t("detalles_de") }} {{ previewed_items.pack.name }}<br>
              <v-chip
                  class="ma-2"
                  :color="getStatusColor(previewed_items.pack.status)"
                  dark
                  >
                {{ previewed_items.pack.status?i18n.t("estados." + previewed_items.pack.status):'' }}
              </v-chip>
        
            </v-card-title>

            <v-card-text>
              <v-container 
                class="element-preview">
                <v-row>

                  <v-col cols="12">
                    <h6>{{ $i18n.t("descripcion_corta") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.pack.short_description"></div>
                    
                    <div v-if="!isEmpty(edited_items.pack.agency_id)">
                      <h6>{{ $i18n.t("agencia_operadora") }}:</h6> 
                      <div class="preview-item-property-value" v-html="previewed_items.pack.agency"></div>
                    </div>

                    <h6>{{ $i18n.t("tipo_de_paquete") }}:</h6> 
                    <div class="preview-item-property-value">{{ $i18n.t("tipos_de_paquetes." + previewed_items.pack.pack_type) }}</div>

                    <div v-if="!isEmpty(edited_items.pack.transport_type)">
                      <h6>{{ $i18n.t("tipo_de_transporte") }}:</h6> 
                      <div class="preview-item-property-value">{{ $i18n.t("tipos_de_transporte." + previewed_items.pack.transport_type) }}</div>
                    </div>
                    
                    <div v-if="previewed_items.pack.pack_type=='senderismo'">
                      <h6>{{ $i18n.t("nivel_de_dificultad") }}:</h6> 
                      <div class="preview-item-property-value">{{ previewed_items.pack.difficulty_level }}</div>
                    </div>

                    <div v-if="edited_items.pack.pack_type=='senderismo'">
                      <h6>{{ i18n.t('distancia_total') }}</h6>
                      <div class="preview-item-property-value">{{ previewed_items.pack.total_distance }} km.</div>
                    </div>

                    <div v-if="edited_items.pack.pack_type=='excursion'">
                      <h6>{{ i18n.t('duracion_en_noches') }}</h6>
                      <div class="preview-item-property-value">{{ previewed_items.pack.duration_in_nights }} {{ i18n.t('noches') }}.</div>
                    </div>

                    <h6>{{ $i18n.t("incluye") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.pack.includes"></div>

                    <h6>{{ $i18n.t("no_incluye") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.pack.not_includes"></div>

                    <div v-if="!isEmpty(previewed_items.pack.itinerary)">
                      <h6>{{ $i18n.t("itinerario") }}:</h6> 
                      <div class="preview-item-property-value" v-html="previewed_items.pack.itinerary"></div>
                    </div>
                    
                    <h6>{{ $i18n.t("observaciones") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.pack.observations"></div>
                    
                    <h6>{{ $i18n.t("recomendaciones") }}:</h6> 
                    <div class="preview-item-property-value" v-html="previewed_items.pack.recommendations"></div>
                    
                    <h6>{{ $i18n.t("tarifas") }}:</h6>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ i18n.t('tarifa') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('valor') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('ocupa_silla') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(rate, index) in previewed_items.pack.rates"
                            :key="index"
                          >
                            <td>{{ rate.name }}</td>
                            <td>{{ rate.value }}</td>
                            <td>{{ rate.seat?i18n.t('si'):i18n.t('no')}}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <h6>{{ $i18n.t("puntos_de_encuentro") }}:</h6>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ i18n.t('lugar') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('hora_de_encuentro') }}
                            </th>
                            <th class="text-left">
                              {{ i18n.t('hora_de_salida') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(meeting_point, index) in previewed_items.pack.meeting_points"
                            :key="index"
                          >
                            <td>{{ meeting_point.place }}</td>
                            <td>{{ meeting_point.time }}</td>
                            <td>{{ meeting_point.departure_time }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>       
                    
                    <div v-if="!isEmpty(previewed_items.pack.pictures)">
                      <h6>{{ $i18n.t("fotos") }}:</h6>
                      <v-container fluid>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="d-flex child-flex"
                            v-for="(picture, index) in previewed_items.pack.pictures"
                            v-bind:key="index"
                          >
                            <v-card class="mb-5">                            
                              <v-img
                                :src="getImageUrl(picture)"
                                max-height="220"
                                contain
                                class="grey darken-4"
                              ></v-img>                                                            
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>

                    <div  v-if="!isEmpty(previewed_items.pack.videos)">
                      <h6>{{ $i18n.t("videos") }}:</h6>
                      <v-container fluid>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                            v-for="(video, index) in previewed_items.pack.videos"
                            v-bind:key="index"
                          >                                                           
                            
                            <v-card class="mb-5">
                              <div v-html="ytEmbedCodeFromUrl( video.url, 'width: 100%; min-height:190px' )"></div>
                              <a class="d-block ml-1 pa-1" target="_blank" :href="video.url">
                                <strong>{{ video.title }}</strong>
                              </a>                                               
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>

                    
                    <div v-if="!isEmpty(edited_items.pack.web_article)">
                      <h6>{{ $i18n.t("articulo_web") }}:</h6> 
                      <div class="preview-item-property-value" v-html="previewed_items.pack.web_article"></div>
                    </div>


                  </v-col>

                </v-row> 

              </v-container>
            </v-card-text>

            <v-card-actions style="position: sticky; bottom: 0">
              <v-spacer></v-spacer>
              <v-btn dark color="cancel-button darken-1" @click="closePreview()">
                {{ $t("cancelar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item) in items"
            :key="item.id"
          >
            <td>
              <v-container>
                <v-row class="pt-2">

                  <v-col cols="12" sm="2">
                      <img 
                        :src="getPrimaryPictureUrl(item)" 
                        class="pack-picture mb-1"
                      >
                      <div class="pack-picture-shadow">
                        <div class="pack-status-button">
                          <v-chip
                            :color="getStatusColor(item.status)"
                            dark
                            small
                          >
                            {{ item.status?i18n.t("estados." + item.status):'' }}
                          </v-chip>
                        </div>                            
                      </div>
                      
                  </v-col>

                  <v-col cols="12" sm="10">
                    <v-container fill-height fluid>

                      <v-row align="center">

                        <v-col cols="12" md="9">
                          <h5>{{ item.name }}</h5>
                          <div>
                            <v-chip
                              small
                              class="my-1 mr-1"
                              color="green"
                              text-color="white"
                              v-for="(rate, index) in item.rates" :key="index"
                              >
                              {{ rate.name }}:  {{formatCurrency( rate.value) }}
                            </v-chip>
                          </div>
                        </v-col>                        
                        
                        <v-col cols="12" md="3" class="justify-space-around">
                          <v-icon class="mr-1 blue--text" @click="$router.push({ name: 'events', query: { pack_id: item.id }})"> mdi-calendar </v-icon>               
                          <v-icon class="mr-1 green--text" @click="addEvent(item)"> mdi-calendar-plus </v-icon>               
                          <v-icon class="mr-1 view-button--text" @click="previewItemDialog(item)"> mdi-card-search</v-icon>
                          <v-icon class="mr-1 edit-button--text" @click="editItemDialog(item)"> mdi-file-document-edit</v-icon>
                          <v-icon class="delete-button--text" @click="deleteItemDialog(item)"> mdi-delete </v-icon>               
                        </v-col>

                      </v-row>
                    </v-container>

                  </v-col>

                </v-row>
              </v-container>
            </td>
          </tr>
        </tbody>
      </template>
      
    </v-data-table>

    <v-pagination
      v-model="datatables.packs.page"
      :length="datatables.packs.page_count"
      v-if="datatables.packs.total_items > 0 && datatables.packs.page_count > 1"
    ></v-pagination>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="true"
          color="blue"
          dark
          fixed
          bottom
          right
          class="fab-custom"
          fab
          @click="editItemDialog()"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-creation</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("nuevo") }}</span>
    </v-tooltip>
    
  </div>
</template>

<style scoped src="@/views/Pack/Packs.css">
</style>

<script>
  import pack from '@/views/Pack/Packs'
  export default pack
</script>
